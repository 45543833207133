/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React, { useRef } from 'react'
import { connect } from 'react-redux'
import {
  Spinner,
  Form,
  Image,
  Container,
  Jumbotron,
  InputGroup,
  Button,
  Row,
  Col,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReCAPTCHA from 'react-google-recaptcha'
import { isMobile } from 'react-device-detect'
import { useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from 'react-popup-alert'
import 'react-popup-alert/dist/index.css'
import DOMHelper from '../../../helper/domhelper'
import Formatter from '../../../helper/formatter'
import HookHelper from '../../../helper/hookhelper'
import utilConstant from '../../../helper/utilconstants'

import './transfer.scss'
import globalConstant from '../../../store/constant/global'
import authConstant from '../../../store/constant/auth'
import produkConstant from '../../../store/constant/produk'
import SidebarComponent from '../../component/sidebar'
import HeaderComponent from '../../component/header'
import LoginModal from '../../dialog/auth/login'
import ListBankDialog from '../../dialog/listBank'
import TransferBankModal from '../../dialog/transfer/bank'

import { trfSpesialInq, trfSpesialPay } from '../../../api/action/transfer/bank'
import { getCustomAdmin } from '../../../api/action/global'

const EcommerceScreen = ({
  appUserId,
  appUserPin,
  appUserBalance,
  appUserIsEmbed,
  appIsLoading,
  appBank,
  dispatch,
}) => {
  const history = useHistory()
  const location = useLocation()
  const [isOpen, setIsOpen] = React.useState(!isMobile)
  const [isInqWeb, setIsInqWeb] = React.useState(false)
  const [alertError, setAlertError] = React.useState('')
  const [loginModalShow, setLoginModalShow] = React.useState(false)
  const [trfBankModalShow, setTrfBankModalShow] = React.useState(false)
  const [userId] = React.useState(!appUserId ? process.env.REACT_APP_USER_DEMO_ID : appUserId)
  const [userPin] = React.useState(
    !appUserId ? process.env.REACT_APP_USER_DEMO_PASSWORD : appUserPin
  )
  const [isSaldoOk, setIsSaldoOk] = React.useState(false)
  const [manualUserPin, setManualUserPin] = React.useState('')
  const [listBankModalShow, setListBankModalShow] = React.useState(false)
  const [listBank, setListBank] = React.useState([])
  const [listBankFiltered, setListBankFiltered] = React.useState([])
  const [searchBank, setSearchBank] = React.useState('')
  const [kodeBank, setKodeBank] = React.useState('')
  const [namaBank, setNamaBank] = React.useState('')
  const [norek, setNorek] = React.useState('')
  const [nominal, setNominal] = React.useState('0')
  const [berita, setBerita] = React.useState('')
  const [dataRes, setDataRes] = React.useState(null)
  const [isShowPin, setIsShowPin] = React.useState(false)
  const [listCustomAdmin, setListCustomAdmin] = React.useState([])
  // const [customAdminProduk, setCustomAdminProduk] = React.useState('')
  const [customAdmin, setCustomAdmin] = React.useState('')
  const [ecommerce, setEcommerce] = React.useState('')

  const recaptchaRef = useRef(null)

  const handleClear = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      if (target === 'norek') {
        setNorek('')
      }
      if (target === 'nominal') {
        setNominal(0)
      }
      if (target === 'berita') {
        setBerita('')
      }

      setIsInqWeb(false)
      setDataRes(null)
    },
    [setNorek, setNominal, setBerita, setIsInqWeb, setDataRes]
  )

  const toggleSidebar = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])

  const openLoginModal = React.useCallback(async () => {
    setLoginModalShow(true)
  }, [setLoginModalShow])

  const confirmLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
    // window.location.reload()
  }, [setLoginModalShow])

  const closeLoginModal = React.useCallback(async () => {
    if (!appUserId) {
      setLoginModalShow('')
      history.push('/')
    } else {
      setLoginModalShow('')
    }
  }, [appUserId, history, setLoginModalShow])

  const closeSaldoModal = React.useCallback(async () => {
    setTrfBankModalShow(false)
  }, [setTrfBankModalShow])

  const openListBankShow = React.useCallback(async () => {
    setListBankModalShow(true)
  }, [setListBankModalShow])

  const closeListBankModal = React.useCallback(async () => {
    setListBankModalShow(false)
  }, [setListBankModalShow])

  const closeError = React.useCallback(async () => {
    setAlertError('')
  }, [setAlertError])

  const showPin = React.useCallback(async () => {
    // eslint-disable-next-line no-shadow
    setIsShowPin((isShowPin) => !isShowPin)
  }, [setIsShowPin])

  const onSearchListBankChange = React.useCallback(
    (e) => {
      const { value } = e.target
      const newData = _.filter(listBank, (item) => {
        return (
          _.includes(item.kodeBank.toLowerCase(), value.toLowerCase()) ||
          _.includes(item.namaBank.toLowerCase(), value.toLowerCase())
        )
      })

      setSearchBank(value)
      setListBankFiltered(newData)
    },
    [listBank, setSearchBank, setListBankFiltered]
  )

  const onBankChange = React.useCallback(
    async (e) => {
      const { target, label } = e.currentTarget.dataset
      setKodeBank(target)
      setNamaBank(label)
      setListBankModalShow(false)

      let produkId = ''
      if (target === utilConstant.trfSpesial.bankCodeBca) {
        produkId = `${utilConstant.trfSpesial.prefixProduct}${utilConstant.trfSpesial.prefixProductBca}`
      } else if (target === utilConstant.trfSpesial.bankCodeBri) {
        produkId = `${utilConstant.trfSpesial.prefixProduct}${utilConstant.trfSpesial.prefixProductBri}`
      } else if (target === utilConstant.trfSpesial.bankCodeBni) {
        produkId = `${utilConstant.trfSpesial.prefixProduct}${utilConstant.trfSpesial.prefixProductBni}`
      } else if (target === utilConstant.trfSpesial.bankCodeMandiri) {
        produkId = `${utilConstant.trfSpesial.prefixProduct}${utilConstant.trfSpesial.prefixProductMandiri}`
      } else {
        produkId = `${utilConstant.trfSpesial.prefixProduct}${utilConstant.trfSpesial.prefixProductDefault}`
      }

      // setCustomAdminProduk(produkId)

      // Get List Biaya Admin
      const response = await dispatch(getCustomAdmin(userId, produkId))
      if (response.isOK()) {
        const dataRespon = response.getData()
        setListCustomAdmin(dataRespon.data)
      } else {
        setAlertError({ rc: response.getRC(), rd: response.getRD() })
        setKodeBank('')
        setNamaBank('')
        // setCustomAdminProduk('')
        setListCustomAdmin([])
      }

      setIsInqWeb(false)
      setDataRes(null)
    },
    [
      userId,
      setKodeBank,
      setNamaBank,
      setListBankModalShow,
      // setCustomAdminProduk,
      setListCustomAdmin,
      setAlertError,
      setIsInqWeb,
      setDataRes,
      dispatch,
    ]
  )

  const onNorekChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setNorek(Formatter.number(value))
    },
    [setNorek]
  )

  const onNominalChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setNominal(Formatter.number(value))
    },
    [setNominal]
  )

  const onBeritaChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setBerita(value)
    },
    [setBerita]
  )

  const onUserPinChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setManualUserPin(value)
    },
    [setManualUserPin]
  )

  const selectProduct = React.useCallback(
    (e) => {
      const { id } = e.currentTarget.dataset
      setCustomAdmin(id)
    },
    [setCustomAdmin]
  )

  const selectEcommerce = React.useCallback(
    (e) => {
      const { id } = e.currentTarget.dataset
      setEcommerce(id)
    },
    [setEcommerce]
  )

  const onProcess = React.useCallback(async () => {
    const userBalance = Math.round(parseFloat(appUserBalance))
    setManualUserPin('')

    if (!ecommerce) {
      setAlertError({ rc: '', rd: 'e-Commerce Harap Dipilih Terlebih Dahulu' })
      setIsInqWeb(false)
      return false
    }

    if (!kodeBank) {
      setAlertError({ rc: '', rd: 'Nama Bank Harap Dipilih Terlebih Dahulu' })
      setIsInqWeb(false)
      return false
    }

    if (!norek) {
      setAlertError({ rc: '', rd: 'Masukkan No Rekening Tujuan Terlebih Dahulu' })
      setIsInqWeb(false)
      return false
    }

    if (parseInt(nominal, 10) <= 0) {
      setAlertError({ rc: '', rd: 'Nominal harus diatas 0' })
      setIsInqWeb(false)
      return false
    }

    if (parseInt(nominal, 10) > userBalance) {
      setAlertError({
        rc: '06',
        rd: 'Saldo anda tidak cukup untuk melakukan transaksi ini.',
      })
      setIsInqWeb(false)
      return false
    }

    if (!customAdmin) {
      setAlertError({ rc: '', rd: 'Pilih Biaya Admin Terlebih Dahulu' })
      setIsInqWeb(false)
      return false
    }

    const recaptchaToken = await recaptchaRef.current.executeAsync()
    recaptchaRef.current.reset()

    const response = await dispatch(
      trfSpesialInq(
        userId,
        userPin,
        customAdmin,
        kodeBank,
        norek,
        nominal,
        berita,
        ecommerce,
        recaptchaToken
      )
    )
    if (response.isOK()) {
      const dataRespon = response.getData()
      const { amount } = dataRespon.includes
      const { admin } = dataRespon.includes
      const total = parseInt(amount, 0) + parseInt(admin, 0)
      if (Math.round(parseFloat(appUserBalance)) >= total) {
        setIsSaldoOk(true)
      }
      setDataRes(dataRespon)

      if (!isMobile) {
        setIsInqWeb(true)
      } else {
        setTrfBankModalShow(true)
      }
    } else {
      setAlertError({ rc: response.getRC(), rd: response.getRD() })
    }

    return true
  }, [
    dispatch,
    userId,
    userPin,
    appUserBalance,
    customAdmin,
    kodeBank,
    norek,
    nominal,
    berita,
    ecommerce,
    setIsInqWeb,
    setDataRes,
    setManualUserPin,
    setAlertError,
    setTrfBankModalShow,
    setIsSaldoOk,
  ])

  const redirectToDeposit = React.useCallback(() => {
    history.push('/deposit')
  }, [history])

  const confirmTransfer = React.useCallback(async () => {
    if (!appUserId) {
      setIsInqWeb(false)
      setLoginModalShow(true)
    }

    if (!manualUserPin) {
      setAlertError({ rc: '', rd: 'Masukkan PIN Terlebih Dahulu' })
    } else if (isSaldoOk) {
      const dataIncludes = dataRes.includes
      const { amount, admin } = dataIncludes
      const reffId = dataIncludes.reff_id

      const responsePay = await dispatch(
        trfSpesialPay(
          userId,
          manualUserPin,
          customAdmin,
          kodeBank,
          norek,
          amount,
          berita,
          admin,
          reffId,
          ecommerce
        )
      )
      if (responsePay.isOK()) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'ecommerce',
            parentPath: location.pathname,
            title: 'Pembayaran E-Commerce',
            icon: 'tags',
            transaksi: responsePay.data.includes.reff_id,
            trxDate: responsePay.data.transmission_datetime,
            trxTime: responsePay.data.transmission_datetime,
            total:
              Number(responsePay.data.includes.amount) +
              Number(responsePay.data.includes.admin) -
              parseInt(responsePay.data.biller_info.diskon, 10),
            customerId: responsePay.data.additional_datas
              ? responsePay.data.additional_datas[2]
              : '',
            discountInfo: responsePay.data.discountInfo,
            info: 'sukses',
          },
        })
      } else if (
        responsePay.getRC() === '' ||
        responsePay.getRC() === '68' ||
        responsePay.getRC().toString() === responsePay.data.includes.reff_id.toString()
      ) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'ecommerce',
            parentPath: location.pathname,
            title: 'Pembayaran E-Commerce',
            icon: 'tags',
            transaksi: responsePay.data.includes.reff_id,
            trxDate: responsePay.data.transmission_datetime,
            trxTime: responsePay.data.transmission_datetime,
            total:
              Number(responsePay.data.includes.amount) +
              Number(responsePay.data.includes.admin) -
              parseInt(responsePay.data.biller_info.diskon, 10),
            customerId: responsePay.data.additional_datas
              ? responsePay.data.additional_datas[2]
              : '',
            discountInfo: responsePay.data.discountInfo,
            info: 'pending',
          },
        })
      } else {
        setAlertError({ rc: responsePay.getResponseCode(), rd: responsePay.getRD() })
      }
    } else {
      redirectToDeposit()
    }
  }, [
    dispatch,
    history,
    location,
    appUserId,
    userId,
    manualUserPin,
    customAdmin,
    berita,
    dataRes,
    isSaldoOk,
    kodeBank,
    norek,
    ecommerce,
    setAlertError,
    redirectToDeposit,
  ])

  React.useEffect(() => {
    DOMHelper.mountClass('transfer-layout')
    HookHelper.resetLoading()

    if (!appUserId) {
      setLoginModalShow(true)
    }

    if (appBank) {
      const listAppBank = []
      const splitAppBank = appBank.split('|')
      const splitCode = splitAppBank[0].split('#')
      const splitCodeBank = splitCode[0].split(':')
      const listCodeBank = splitCodeBank[1].split(';')

      const splitName = splitAppBank[1].split('#')
      const splitNameBank = splitName[0].split(':')
      const listNameBank = splitNameBank[1].split(';')

      for (let i = 0; i < listCodeBank.length; i += 1) {
        listAppBank.push({ kodeBank: listCodeBank[i], namaBank: listNameBank[i] })
      }

      setListBank(listAppBank)
      setListBankFiltered(listAppBank)
    }

    return () => DOMHelper.unmountClass(['transfer-layout'])
  }, [appUserId, appBank, setLoginModalShow, setListBank, setListBankFiltered])

  return (
    <Container fluid className="transfer-layout">
      {!appUserIsEmbed && (
        <Row className="header">
          <Col>
            <HeaderComponent title="Transaksi Bank Spesial" onToggle={toggleSidebar} />
          </Col>
        </Row>
      )}

      <Row>
        {!appUserIsEmbed && (
          <Col
            md={2}
            className={classNames('col-sidebar', { 'is-open': isOpen, 'is-mobile': isMobile })}
          >
            <SidebarComponent isOpen={isOpen} />
          </Col>
        )}

        <Col
          md={{
            span: appUserIsEmbed ? 12 : isOpen ? 10 : 12,
            offset: appUserIsEmbed ? 0 : isOpen ? 2 : 0,
          }}
        >
          <Jumbotron fluid className="banner">
            <Row>
              <Col>
                <Row>
                  <span className={classNames('banner-title', { 'is-mobile': isMobile })}>
                    <FontAwesomeIcon icon="shopping-cart" fixedWidth className="mr-3" />
                    Pembayaran E-Commerce
                  </span>
                </Row>
              </Col>

              {!appUserId ? (
                <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                  <Button
                    className={classNames('button-login', { 'is-mobile': isMobile })}
                    type="button"
                    onClick={openLoginModal}
                  >
                    Daftar / Login
                  </Button>
                </Col>
              ) : (
                <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                  <Row>
                    <Col lg={10} xs={10} md={10}>
                      <div className="banner-saldo">Saldo Anda</div>
                      <div className="banner-nominal">
                        Rp. {Formatter.price(Math.round(parseFloat(appUserBalance)))}
                      </div>
                    </Col>
                    <Col lg={2} xs={2} md={2}>
                      <span className="banner-title">
                        <FontAwesomeIcon icon="wallet" fixedWidth className="mr-3" />
                      </span>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Jumbotron>

          <div className="content-layanan">
            <Row className="justify-content-md-center">
              <Col lg={isInqWeb ? 7 : 8} xs={12} md={isInqWeb ? 7 : 8} className="bd-form">
                <Form className="form-pelanggan">
                  {/* <Form.Group>
                    <Row className="start txt-18 color-primary txt-normal mb-10">
                      Dengan menggunakan layanan ini Anda bisa memilih biaya Admin untuk transaksi
                      transfer uang
                    </Row>
                  </Form.Group> */}
                  <Form.Group>
                    <Form.Label className="form-label start">Pilih Ecommerce</Form.Label>
                    <Row>
                      <Col
                        className="cl-list-emoney pointing"
                        xs={6}
                        md={4}
                        lg={3}
                        data-id={utilConstant.trfSpesial.eCommerceTokopedia}
                        onClick={selectEcommerce}
                      >
                        <div
                          className={classNames('ct-list-emoney', {
                            'selected-produk':
                              utilConstant.trfSpesial.eCommerceTokopedia === ecommerce,
                          })}
                        >
                          <Row className="justify-content-md-center row-ct-list-emoney">
                            <Col>
                              <Image
                                className="image-logo"
                                src="/icon/ecommerce/tokopedia.png"
                                fluid
                              />
                            </Col>
                          </Row>
                          <Row className="justify-content-md-center row-provider">
                            <Col className="subcol-label txt-14">Tokopedia</Col>
                          </Row>
                        </div>
                      </Col>
                      <Col
                        className="cl-list-emoney pointing"
                        xs={6}
                        md={4}
                        lg={3}
                        data-id={utilConstant.trfSpesial.eCommerceShopee}
                        onClick={selectEcommerce}
                      >
                        <div
                          className={classNames('ct-list-emoney', {
                            'selected-produk':
                              utilConstant.trfSpesial.eCommerceShopee === ecommerce,
                          })}
                        >
                          <Row className="justify-content-md-center row-ct-list-emoney">
                            <Col>
                              <Image
                                className="image-logo"
                                src="/icon/ecommerce/shopee.png"
                                fluid
                              />
                            </Col>
                          </Row>
                          <Row className="justify-content-md-center row-provider">
                            <Col className="subcol-label txt-14">Shopee</Col>
                          </Row>
                        </div>
                      </Col>
                      <Col
                        className="cl-list-emoney pointing"
                        xs={6}
                        md={4}
                        lg={3}
                        data-id={utilConstant.trfSpesial.eCommerceBukalapak}
                        onClick={selectEcommerce}
                      >
                        <div
                          className={classNames('ct-list-emoney', {
                            'selected-produk':
                              utilConstant.trfSpesial.eCommerceBukalapak === ecommerce,
                          })}
                        >
                          <Row className="justify-content-md-center row-ct-list-emoney">
                            <Col>
                              <Image
                                className="image-logo"
                                src="/icon/ecommerce/bukalapak.png"
                                fluid
                              />
                            </Col>
                          </Row>
                          <Row className="justify-content-md-center row-provider">
                            <Col className="subcol-label txt-14">Bukalapak</Col>
                          </Row>
                        </div>
                      </Col>
                      <Col
                        className="cl-list-emoney pointing"
                        xs={6}
                        md={4}
                        lg={3}
                        data-id={utilConstant.trfSpesial.eCommerceLazada}
                        onClick={selectEcommerce}
                      >
                        <div
                          className={classNames('ct-list-emoney', {
                            'selected-produk':
                              utilConstant.trfSpesial.eCommerceLazada === ecommerce,
                          })}
                        >
                          <Row className="justify-content-md-center row-ct-list-emoney">
                            <Col>
                              <Image
                                className="image-logo"
                                src="/icon/ecommerce/lazada.png"
                                fluid
                              />
                            </Col>
                          </Row>
                          <Row className="justify-content-md-center row-provider">
                            <Col className="subcol-label txt-14">Lazada</Col>
                          </Row>
                        </div>
                      </Col>
                      <Col
                        className="cl-list-emoney pointing"
                        xs={6}
                        md={4}
                        lg={3}
                        data-id={utilConstant.trfSpesial.eCommerceBlibli}
                        onClick={selectEcommerce}
                      >
                        <div
                          className={classNames('ct-list-emoney', {
                            'selected-produk':
                              utilConstant.trfSpesial.eCommerceBlibli === ecommerce,
                          })}
                        >
                          <Row className="justify-content-md-center row-ct-list-emoney">
                            <Col>
                              <Image
                                className="image-logo"
                                src="/icon/ecommerce/blibli.png"
                                fluid
                              />
                            </Col>
                          </Row>
                          <Row className="justify-content-md-center row-provider">
                            <Col className="subcol-label txt-14">Blibli</Col>
                          </Row>
                        </div>
                      </Col>
                      <Col
                        className="cl-list-emoney pointing"
                        xs={6}
                        md={4}
                        lg={3}
                        data-id={utilConstant.trfSpesial.eCommerceLainnya}
                        onClick={selectEcommerce}
                      >
                        <div
                          className={classNames('ct-list-emoney', {
                            'selected-produk':
                              utilConstant.trfSpesial.eCommerceLainnya === ecommerce,
                          })}
                        >
                          <Row className="justify-content-md-center row-ct-list-emoney">
                            <Col>
                              <Image
                                className="image-logo"
                                src="/icon/ecommerce/shopping_cart.png"
                                fluid
                              />
                            </Col>
                          </Row>
                          <Row className="justify-content-md-center row-provider">
                            <Col className="subcol-label txt-14">e-Commerce Lainnya</Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="form-label start">Pilih Bank</Form.Label>
                    <InputGroup
                      className="mb-12"
                      onClick={openListBankShow}
                      style={{ cursor: 'pointer' }}
                    >
                      <Form.Control
                        placeholder="---Pilih Bank---"
                        value={namaBank}
                        readOnly
                        style={{ cursor: 'pointer' }}
                      />
                      <InputGroup.Append>
                        <FontAwesomeIcon icon="chevron-down" className="form-icon single" />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="form-label start">No. Rekening Tujuan</Form.Label>
                    <InputGroup className="mb-12">
                      <Form.Control
                        placeholder="No. Rekening Tujuan"
                        name="norek"
                        value={Formatter.serial(norek)}
                        onChange={onNorekChange}
                        disabled={isInqWeb || trfBankModalShow}
                      />
                      <InputGroup.Append className="flex align-items-center">
                        <Image
                          className={classNames('form-icon-btn', { 'ic-mobile': isMobile })}
                          src="/icon/clear.png"
                          data-target="norek"
                          onClick={handleClear}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="form-label start">Nominal Transfer</Form.Label>
                    <InputGroup className="mb-12">
                      <Form.Control
                        placeholder="Masukkan Nominal Transfer"
                        value={Formatter.price(nominal)}
                        onChange={onNominalChange}
                        disabled={isInqWeb || trfBankModalShow}
                      />
                      <InputGroup.Append className="flex align-items-center">
                        <Image
                          className={classNames('form-icon-btn', { 'ic-mobile': isMobile })}
                          src="/icon/clear.png"
                          data-target="nominal"
                          onClick={handleClear}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="form-label start">Berita Transfer (Opsional)</Form.Label>
                    <InputGroup className="mb-12">
                      <Form.Control
                        placeholder="Cth: Tabungan Umroh Bulan Juli"
                        value={berita}
                        onChange={onBeritaChange}
                      />
                      <InputGroup.Append className="flex align-items-center">
                        <Image
                          className={classNames('form-icon-btn', { 'ic-mobile': isMobile })}
                          src="/icon/clear.png"
                          data-target="berita"
                          onClick={handleClear}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  {listCustomAdmin.length > 0 && (
                    <Form.Group>
                      <Form.Label className="form-label start">Pilih Biaya Admin</Form.Label>
                      <Row>
                        {listCustomAdmin.map((value) => (
                          <Col
                            className="icon-col-game produk pointing"
                            xs={6}
                            md={4}
                            lg={3}
                            key={value.id_produk}
                            data-id={value.id_produk}
                            onClick={selectProduct}
                          >
                            <div
                              className={classNames('col-list-game produk pointing', {
                                'selected-produk': value.id_produk === customAdmin,
                              })}
                            >
                              <div className="produk-body">
                                <span
                                  className={classNames('produk-label', {
                                    'is-mobile': isMobile,
                                    'selected-produk': value.id_produk === customAdmin,
                                  })}
                                >
                                  {`Rp. ${Formatter.price(
                                    Math.round(Math.abs(value.biaya_admin))
                                  )}`}
                                </span>
                                {Math.round(Math.abs(value.up_harga)) > 0 && (
                                  <span
                                    className={classNames(
                                      'produk-sub-label-2 color-primary txt-bold',
                                      {
                                        'is-mobile': isMobile,
                                        'selected-produk': value.id_produk === customAdmin,
                                      }
                                    )}
                                  >
                                    {`Fee Rp. ${Formatter.price(
                                      Math.round(Math.abs(value.up_harga))
                                    )}`}
                                  </span>
                                )}
                              </div>
                              <div
                                className={classNames('produk-footer', {
                                  'selected-produk': value.id_produk === customAdmin,
                                })}
                              />
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Form.Group>
                  )}
                  <Row className="justify-content-md-center">
                    <Col className="box-balance">
                      <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Col lg={1} xs={2} md={2}>
                          <Image src="/img/sc.png" roundedCircle />
                        </Col>
                        <Col lg={6} xs={5} md={5}>
                          Saldo Speedcash
                        </Col>
                        <Col lg={5} xs={5} md={5} className="text-end txt-bold">
                          {appUserBalance
                            ? `Rp ${Formatter.price(Math.round(parseFloat(appUserBalance)))}`
                            : 'Rp 0,-'}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
                <Row className="row-proses">
                  <Col className="nominal-minimal" />
                  <Col style={{ margin: 'auto' }}>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_GRECAPTCHA_SITE_KEY}
                      size="invisible"
                      ref={recaptchaRef}
                    />
                    <Button
                      className="btn-process"
                      size="lg"
                      block
                      onClick={onProcess}
                      disabled={appIsLoading}
                    >
                      {appIsLoading && !isMobile && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {appIsLoading && !isMobile ? ' Mohon Tunggu...' : 'Lanjutkan'}
                    </Button>
                  </Col>
                </Row>
              </Col>
              {isInqWeb && (
                <>
                  <Col md="auto" />
                  <Col lg={4} xs={12} md={4} className="bd-form-inq">
                    <Row className="justify-content-md-center">
                      <Col className="inq-header txt-bold">Konfirmasi Transfer</Col>
                    </Row>
                    {dataRes &&
                      dataRes.strukMaps.map((val) => (
                        <Row key={Formatter.upperFirst(Object.keys(val)[0])}>
                          <Col>{Formatter.upperFirst(Object.keys(val)[0])}</Col>
                          <Col className="inq-end-label">{val ? val[Object.keys(val)[0]] : ''}</Col>
                        </Row>
                      ))}
                    <Row className="justify-content-md-center">
                      <Col className="inq-balance">
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                          <Col lg={2} xs={2} md={2}>
                            <Image src="/img/sc.png" roundedCircle />
                          </Col>
                          <Col lg={5} xs={5} md={5}>
                            Saldo Speedcash
                          </Col>
                          <Col lg={5} xs={5} md={5} className="inq-end-label txt-bold">
                            {appUserBalance
                              ? `Rp ${Formatter.price(Math.round(parseFloat(appUserBalance)))}`
                              : 'Rp 0,-'}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center inq-body-last">
                      <Form className="form-pelanggan">
                        <Form.Group>
                          <Form.Label
                            className="form-label text-center color-primary txt-16 txt-bold"
                            style={{ width: '100%' }}
                          >
                            Masukkan PIN
                          </Form.Label>
                          <InputGroup className="mb-12">
                            <Form.Control
                              type={isShowPin ? 'text' : 'password'}
                              className="text-center"
                              maxLength={6}
                              value={manualUserPin}
                              onChange={onUserPinChange}
                            />
                            <InputGroup.Append
                              className="pointing"
                              style={{ alignItems: 'center' }}
                            >
                              <FontAwesomeIcon
                                icon={isShowPin ? 'eye-slash' : 'eye'}
                                data-target="pin"
                                onClick={showPin}
                              />
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>
                      </Form>
                    </Row>
                    {!isSaldoOk && appUserId && (
                      <Row className="justify-content-md-center inq-body-last">
                        <Col className="inq-balance not-ok">
                          <Row className="txt-bold">
                            Saldo Anda belum mencukupi untuk transaksi ini. Segera Top Up
                          </Row>
                        </Col>
                      </Row>
                    )}
                    <Row className="justify-content-md-center">
                      <Col>
                        <Button
                          className="btn-process"
                          type="button"
                          block
                          onClick={
                            appUserId
                              ? isSaldoOk
                                ? confirmTransfer
                                : redirectToDeposit
                              : openLoginModal
                          }
                        >
                          {appIsLoading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          {appIsLoading
                            ? ' Mohon Tunggu...'
                            : appUserId
                            ? isSaldoOk
                              ? 'Transfer Sekarang'
                              : 'Top Up Saldo'
                            : 'Daftar / Login'}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </Col>
      </Row>
      <Alert
        header="Perhatian"
        btnText="OK"
        text={alertError.rd}
        type="error"
        show={!!alertError.rd}
        onClosePress={closeError}
        showBorderBottom
        alertStyles={{ justifyContent: 'center' }}
      />
      <LoginModal show={!!loginModalShow} onHide={closeLoginModal} onConfirm={confirmLoginModal} />
      <ListBankDialog
        show={!!listBankModalShow}
        onHide={closeListBankModal}
        onConfirm={onBankChange}
        search={searchBank}
        onSearchChange={onSearchListBankChange}
        data={listBankFiltered}
      />
      <TransferBankModal
        show={!!trfBankModalShow}
        onHide={closeSaldoModal}
        onConfirm={confirmTransfer}
        dataRes={dataRes}
        pin={manualUserPin}
        balance={appUserBalance}
        onUserPinChange={onUserPinChange}
        isShowPin={isShowPin}
        showPin={showPin}
        isSaldoOk={isSaldoOk}
      />
    </Container>
  )
}

EcommerceScreen.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  appUserPin: '',
  appUserBalance: 0,
  appUserIsEmbed: false,
  appBank: '',
}

EcommerceScreen.propTypes = {
  appUserId: PropTypes.string,
  appUserPin: PropTypes.string,
  appUserBalance: PropTypes.number,
  appUserIsEmbed: PropTypes.bool,
  appIsLoading: PropTypes.bool,
  appBank: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserPin: state.Auth[authConstant.USER_PIN],
    appUserBalance: state.Auth[authConstant.USER_BALANCE],
    appUserIsEmbed: state.Auth[authConstant.USER_IS_EMBED],
    appBank: state.Produk[produkConstant.PRODUK_BANK],
  }),
  (dispatch) => ({ dispatch })
)(EcommerceScreen)
