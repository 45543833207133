import LayananMessage from '../../message/mp/layanan'
import MessageSender from '../../sender'
import authConstant from '../../../store/constant/auth'

export const transferBankInq =
  (userId, pin, kodeBank, norek, nominal, berita, recaptchaToken = '') =>
  async (dispatch) => {
    const msg = new LayananMessage()
    msg.setProcessingCode('COTINQ')
    msg.setOutletID(userId)
    msg.setPIN(pin)
    msg.data.includes.product_code = 'SCCOTACIMB'
    msg.data.includes.amount = nominal

    msg.setAdditionalDatum(2, norek)
    msg.setAdditionalDatum(7, kodeBank)
    msg.setAdditionalDatum(9, 'IDR')
    msg.setAdditionalDatum(10, nominal)
    msg.setAdditionalDatum(11, berita)
    msg.setAdditionalDatum(16, process.env.REACT_APP_VERSION_CODE)

    msg.data.campaign.utm_user_id = userId
    msg.data.recaptchaToken = recaptchaToken

    const response = await new MessageSender().doPost(msg)
    const msg2 = new LayananMessage(response)
    if (msg2.isOK()) {
      await dispatch({
        type: authConstant.USER_BALANCE,
        payload: Number(msg2.data.includes.balance),
      })
    }

    return msg2
  }

export const transferBankPay =
  (userId, pin, kodeBank, norek, nominal, berita, admin, reffId) => async (dispatch) => {
    const msg = new LayananMessage()
    msg.setProcessingCode('COTPAY')
    msg.setOutletID(userId)
    msg.setPIN(pin)
    msg.setAsManualPin()
    msg.data.includes.product_code = 'SCCOTACIMB'
    msg.data.includes.amount = nominal
    msg.data.includes.admin = admin
    msg.data.includes.reff_id = reffId

    msg.setAdditionalDatum(2, norek)
    msg.setAdditionalDatum(7, kodeBank)
    msg.setAdditionalDatum(9, 'IDR')
    msg.setAdditionalDatum(10, nominal)
    msg.setAdditionalDatum(11, berita)
    msg.setAdditionalDatum(16, process.env.REACT_APP_VERSION_CODE)

    msg.data.campaign.utm_user_id = userId

    const response = await new MessageSender().doPost(msg)
    const msg2 = new LayananMessage(response)
    if (msg2.isOK()) {
      await dispatch({
        type: authConstant.USER_BALANCE,
        payload: Number(msg2.data.includes.balance),
      })
    }

    return msg2
  }

export const trfSpesialInq =
  (userId, pin, idProduk, kodeBank, norek, nominal, berita, ecommerce = '', recaptchaToken = '') =>
  async (dispatch) => {
    const msg = new LayananMessage()
    msg.setProcessingCode('COTINQ')
    msg.setOutletID(userId)
    msg.setPIN(pin)
    msg.data.includes.product_code = idProduk
    msg.data.includes.amount = nominal

    msg.setAdditionalDatum(2, norek)
    msg.setAdditionalDatum(7, kodeBank)
    msg.setAdditionalDatum(9, 'IDR')
    msg.setAdditionalDatum(10, nominal)
    msg.setAdditionalDatum(11, berita)
    msg.setAdditionalDatum(16, process.env.REACT_APP_VERSION_CODE)

    if (ecommerce !== '') {
      msg.setAdditionalDatum(19, ecommerce)
    }

    msg.data.campaign.utm_user_id = userId
    msg.data.recaptchaToken = recaptchaToken

    const response = await new MessageSender().doPost(msg)
    const msg2 = new LayananMessage(response)
    if (msg2.isOK()) {
      await dispatch({
        type: authConstant.USER_BALANCE,
        payload: Number(msg2.data.includes.balance),
      })
    }

    return msg2
  }

export const trfSpesialPay =
  (userId, pin, idProduk, kodeBank, norek, nominal, berita, admin, reffId, ecommerce = '') =>
  async (dispatch) => {
    const msg = new LayananMessage()
    msg.setProcessingCode('COTPAY')
    msg.setOutletID(userId)
    msg.setPIN(pin)
    msg.setAsManualPin()
    msg.data.includes.product_code = idProduk
    msg.data.includes.amount = nominal
    msg.data.includes.admin = admin
    msg.data.includes.reff_id = reffId

    msg.setAdditionalDatum(2, norek)
    msg.setAdditionalDatum(7, kodeBank)
    msg.setAdditionalDatum(9, 'IDR')
    msg.setAdditionalDatum(10, nominal)
    msg.setAdditionalDatum(11, berita)
    msg.setAdditionalDatum(16, process.env.REACT_APP_VERSION_CODE)

    if (ecommerce !== '') {
      msg.setAdditionalDatum(19, ecommerce)
    }

    msg.data.campaign.utm_user_id = userId

    const response = await new MessageSender().doPost(msg)
    const msg2 = new LayananMessage(response)
    if (msg2.isOK()) {
      await dispatch({
        type: authConstant.USER_BALANCE,
        payload: Number(msg2.data.includes.balance),
      })
    }

    return msg2
  }
