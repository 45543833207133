import LayananMessage from '../../message/mp/layanan'
import MessageSender from '../../sender'
import authConstant from '../../../store/constant/auth'
import DateTime from '../../../helper/datetime'

export const tagihanInq =
  (
    outletId,
    pin,
    groupProduk,
    produk,
    customer,
    nominal = null,
    voucherQty = '0',
    tahunPajak = null,
    nomorMesin = null,
    nik = null,
    nomorHp = null,
    email = null,
    recaptchaToken = ''
  ) =>
  async (dispatch) => {
    const msg = new LayananMessage()
    msg.setProcessingCode('INQ')
    msg.setOutletID(outletId)
    msg.setPIN(pin)
    msg.data.includes.product_code = produk
    msg.data.includes.customer_id_1 = customer
    if (produk !== 'ASRBPJSTK') {
      msg.data.includes.voucher_group = groupProduk
      msg.data.includes.voucher_qty = voucherQty
    }

    msg.setAdditionalDatum(0, customer)

    if (produk === 'ASRBPJSTK') {
      msg.setAdditionalDatum(8, 'bpjs_inq_by_nik')
      msg.setAdditionalDatum(9, customer)
    }

    if (produk === 'PKBJATIM') {
      msg.setAdditionalDatum(1, nik)
      msg.setAdditionalDatum(4, nomorHp)
      msg.setAdditionalDatum(8, email)
      msg.setAdditionalDatum(23, nomorMesin)
    }

    if (groupProduk === 'ASURANSI' || groupProduk === 'JIWASRAYA') {
      msg.setAdditionalDatum(10, customer)
      msg.setAdditionalDatum(24, DateTime.today(DateTime.FMT_DATE_YMD))
      msg.setAdditionalDatum(28, DateTime.today(DateTime.FMT_DATE_TIME_YMDHM))
    }

    if (nominal) {
      msg.data.includes.amount = nominal
      msg.setAdditionalDatum(17, nominal)
    }

    if (tahunPajak) {
      msg.setAdditionalDatum(19, tahunPajak)
    }

    msg.data.campaign.utm_user_id = outletId
    msg.data.recaptchaToken = recaptchaToken

    const response = await new MessageSender().doPost(msg)
    const msg2 = new LayananMessage(response)

    if (msg2.isOK()) {
      await dispatch({
        type: authConstant.USER_BALANCE,
        payload: Number(msg2.data.includes.balance),
      })
    }

    return msg2
  }

export const tagihanPay =
  (
    outletId,
    pin,
    voucherQty,
    groupProduk,
    produk,
    customer,
    amount,
    admin,
    reffId,
    tahunPajak = null,
    nomorMesin = null,
    nik = null,
    nomorHp = null,
    email = null
  ) =>
  async (dispatch) => {
    const msg = new LayananMessage()
    msg.setProcessingCode('PAY')
    msg.setOutletID(outletId)
    msg.setPIN(pin)
    msg.data.includes.product_code = produk
    msg.data.includes.customer_id_1 = customer
    if (produk !== 'ASRBPJSTK') {
      msg.data.includes.voucher_group = groupProduk
      msg.data.includes.voucher_qty = voucherQty
    }
    msg.data.includes.amount = amount
    msg.data.includes.admin = admin
    msg.data.includes.reff_id = reffId
    msg.setAdditionalDatum(0, customer)
    msg.setAdditionalDatum(17, amount)

    if (produk === 'ASRBPJSTK') {
      msg.setAdditionalDatum(8, 'bpjs_inq_by_nik')
      msg.setAdditionalDatum(9, customer)
    }

    if (produk === 'PKBJATIM') {
      msg.setAdditionalDatum(1, nik)
      msg.setAdditionalDatum(4, nomorHp)
      msg.setAdditionalDatum(8, email)
      msg.setAdditionalDatum(23, nomorMesin)
    }

    if (groupProduk === 'ASURANSI' || groupProduk === 'JIWASRAYA') {
      msg.setAdditionalDatum(10, customer)
      msg.setAdditionalDatum(24, DateTime.today(DateTime.FMT_DATE_YMD))
      msg.setAdditionalDatum(28, DateTime.today(DateTime.FMT_DATE_TIME_YMDHM))
    }

    if (tahunPajak) {
      msg.setAdditionalDatum(19, tahunPajak)
    }

    msg.data.campaign.utm_user_id = outletId

    const response = await new MessageSender().doPost(msg)
    const msg2 = new LayananMessage(response)

    if (msg2.isOK()) {
      await dispatch({
        type: authConstant.USER_BALANCE,
        payload: Number(msg2.data.includes.balance),
      })
    }

    return msg2
  }

export const djkmInq =
  (outletId, pin, produk, customer, recaptchaToken = '') =>
  async (dispatch) => {
    const msg = new LayananMessage()
    msg.setProcessingCode('INQ')
    msg.setOutletID(outletId)
    msg.setPIN(pin)
    msg.data.includes.product_code = produk
    msg.data.includes.customer_id_1 = customer
    msg.data.campaign.utm_user_id = outletId
    msg.data.recaptchaToken = recaptchaToken

    const response = await new MessageSender().doPost(msg)
    const msg2 = new LayananMessage(response)

    if (msg2.isOK()) {
      await dispatch({
        type: authConstant.USER_BALANCE,
        payload: Number(msg2.data.includes.balance),
      })
    }

    return msg2
  }

export const djkmPay = (outletId, pin, produk, customer, reffId) => async (dispatch) => {
  const msg = new LayananMessage()
  msg.setProcessingCode('PAY')
  msg.setOutletID(outletId)
  msg.setPIN(pin)
  msg.data.includes.product_code = produk
  msg.data.includes.customer_id_1 = customer
  msg.data.includes.reff_id = reffId
  msg.setAdditionalDatum(0, customer)
  msg.data.campaign.utm_user_id = outletId

  const response = await new MessageSender().doPost(msg)
  const msg2 = new LayananMessage(response)

  if (msg2.isOK()) {
    await dispatch({
      type: authConstant.USER_BALANCE,
      payload: Number(msg2.data.includes.balance),
    })
  }

  return msg2
}

export const rumahZakatInq =
  (
    outletId,
    pin,
    groupProduk,
    produk,
    customer,
    notelp,
    alamat,
    propinsi,
    kota,
    donasi,
    voucherQty = '0',
    recaptchaToken = ''
  ) =>
  async (dispatch) => {
    const msg = new LayananMessage()
    msg.setProcessingCode('INQ')
    msg.setOutletID(outletId)
    msg.setPIN(pin)
    msg.data.includes.voucher_group = groupProduk
    msg.data.includes.product_code = produk
    msg.data.includes.voucher_qty = voucherQty
    msg.data.includes.customer_id_1 = customer

    if (donasi && parseInt(donasi, 10) > 0) {
      msg.data.includes.amount = donasi
    }

    msg.setAdditionalDatum(8, produk)
    msg.setAdditionalDatum(9, produk)
    msg.setAdditionalDatum(11, notelp)
    msg.setAdditionalDatum(12, customer)

    if (alamat) {
      msg.setAdditionalDatum(13, alamat)
    }
    if (propinsi) {
      msg.setAdditionalDatum(14, propinsi)
    }
    if (kota) {
      msg.setAdditionalDatum(15, kota)
    }

    msg.data.campaign.utm_user_id = outletId
    msg.data.recaptchaToken = recaptchaToken

    const response = await new MessageSender().doPost(msg)
    const msg2 = new LayananMessage(response)

    if (msg2.isOK()) {
      await dispatch({
        type: authConstant.USER_BALANCE,
        payload: Number(msg2.data.includes.balance),
      })
    }

    return msg2
  }

export const rumahZakatPay =
  (
    outletId,
    pin,
    voucherQty,
    groupProduk,
    produk,
    customer,
    notelp,
    alamat,
    propinsi,
    kota,
    amount,
    admin,
    reffId
  ) =>
  async (dispatch) => {
    const msg = new LayananMessage()
    msg.setProcessingCode('PAY')
    msg.setOutletID(outletId)
    msg.setPIN(pin)
    msg.data.includes.voucher_group = groupProduk
    msg.data.includes.product_code = produk
    msg.data.includes.voucher_qty = voucherQty
    msg.data.includes.customer_id_1 = customer
    msg.data.includes.amount = amount
    msg.data.includes.admin = admin
    msg.data.includes.reff_id = reffId

    msg.setAdditionalDatum(8, produk)
    msg.setAdditionalDatum(9, produk)
    msg.setAdditionalDatum(11, notelp)
    msg.setAdditionalDatum(12, customer)

    if (alamat) {
      msg.setAdditionalDatum(13, alamat)
    }
    if (propinsi) {
      msg.setAdditionalDatum(14, propinsi)
    }
    if (kota) {
      msg.setAdditionalDatum(15, kota)
    }

    msg.data.campaign.utm_user_id = outletId

    const response = await new MessageSender().doPost(msg)
    const msg2 = new LayananMessage(response)

    if (msg2.isOK()) {
      await dispatch({
        type: authConstant.USER_BALANCE,
        payload: Number(msg2.data.includes.balance),
      })
    }

    return msg2
  }
