/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React, { useRef } from 'react'
import { connect } from 'react-redux'
import {
  Spinner,
  Form,
  InputGroup,
  Container,
  Jumbotron,
  Button,
  Image,
  Row,
  Col,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReCAPTCHA from 'react-google-recaptcha'
import { isMobile } from 'react-device-detect'
import { useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from 'react-popup-alert'
import 'react-popup-alert/dist/index.css'
import DOMHelper from '../../../helper/domhelper'
import Formatter from '../../../helper/formatter'
import HookHelper from '../../../helper/hookhelper'

import './layanan.scss'
import globalConstant from '../../../store/constant/global'
import authConstant from '../../../store/constant/auth'
import produkConstant from '../../../store/constant/produk'
import SidebarComponent from '../../component/sidebar'
import HeaderComponent from '../../component/header'
import LoginModal from '../../dialog/auth/login'
import ZakatModal from '../../dialog/layanan/zakat'
import ZakatListDialog from '../../dialog/layanan/zakatList'
import PropinsiDialog from '../../dialog/propinsi'
import KotaDialog from '../../dialog/kota'

import { rumahZakatInq, rumahZakatPay } from '../../../api/action/layanan/tagihan'
import { listProdukTagihan } from '../../../api/action/global'

const ZakatScreen = ({
  appUserId,
  appUserPin,
  appUserPhone,
  appUserBalance,
  appUserIsEmbed,
  appIsLoading,
  appPropinsi,
  appKota,
  appProdukTagihan,
  dispatch,
}) => {
  const GROUP_PRODUK = 'RUMAH ZAKAT'
  const produkSetDonasi = ['RZZ', 'RZDUK', 'RZIS']
  const history = useHistory()
  const location = useLocation()
  const inqZakatRef = useRef(null)
  const [isOpen, setIsOpen] = React.useState(!isMobile)
  const [isInqWeb, setIsInqWeb] = React.useState(false)
  const [alertError, setAlertError] = React.useState('')
  const [loadingGetProduk, setLoadingGetProduk] = React.useState(false)
  const [loginModalShow, setLoginModalShow] = React.useState(false)
  const [tagihanModalShow, setTagihanModalShow] = React.useState(false)
  const [userId] = React.useState(!appUserId ? process.env.REACT_APP_USER_DEMO_ID : appUserId)
  const [userPin] = React.useState(
    !appUserId ? process.env.REACT_APP_USER_DEMO_PASSWORD : appUserPin
  )
  const [userPhone] = React.useState(
    !appUserId ? process.env.REACT_APP_USER_DEMO_TELP : appUserPhone
  )
  const [isSaldoOk, setIsSaldoOk] = React.useState(false)
  const [voucherQty] = React.useState('0')
  const [produk, setProduk] = React.useState('')
  const [produkLabel, setProdukLabel] = React.useState('')
  const [produkHarga, setProdukHarga] = React.useState(0)
  const [customer, setCustomer] = React.useState('')
  const [notelp, setNotelp] = React.useState('')
  const [alamat, setAlamat] = React.useState('')
  const [propinsi, setPropinsi] = React.useState('')
  const [propinsiLabel, setPropinsiLabel] = React.useState('')
  const [kota, setKota] = React.useState('')
  const [kotaLabel, setKotaLabel] = React.useState('')
  const [donasi, setDonasi] = React.useState('0')
  const [dataPropinsi, setDataPropinsi] = React.useState([])
  const [dataKotaAll, setDataKotaAll] = React.useState([])
  const [dataKota, setDataKota] = React.useState([])
  const [dataPropinsiFiltered, setDataPropinsiFiltered] = React.useState([])
  const [dataKotaFiltered, setDataKotaFiltered] = React.useState([])
  const [dataProduk, setDataProduk] = React.useState([])
  const [dataProdukFiltered, setDataProdukFiltered] = React.useState([])
  const [dataRes, setDataRes] = React.useState(null)
  const [serachProdukShow, setSearchProdukShow] = React.useState(false)
  const [searchProduk, setSearchProduk] = React.useState('')
  const [searchPropinsiShow, setSearchPropinsiShow] = React.useState(false)
  const [searchPropinsi, setSearchPropinsi] = React.useState('')
  const [searchKotaShow, setSearchKotaShow] = React.useState(false)
  const [searchKota, setSearchKota] = React.useState('')

  const recaptchaRef = useRef(null)

  const toggleSidebar = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])

  const closeError = React.useCallback(() => setAlertError(''), [])

  const openLoginModal = React.useCallback(async () => {
    setLoginModalShow(true)
  }, [])

  const confirmLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
    // window.location.reload()
  }, [])

  const closeLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
  }, [])

  const closeTagihanModal = React.useCallback(async () => {
    setTagihanModalShow(false)
  }, [])

  const openProdukShow = React.useCallback(async () => {
    setSearchProdukShow(true)
  }, [setSearchProdukShow])

  const closeProdukShow = React.useCallback(async () => {
    setSearchProdukShow(false)
  }, [setSearchProdukShow])

  const openPropinsiShow = React.useCallback(async () => {
    setSearchPropinsiShow(true)
  }, [setSearchPropinsiShow])

  const closePropinsiShow = React.useCallback(async () => {
    setSearchPropinsiShow(false)
  }, [setSearchPropinsiShow])

  const openKotaShow = React.useCallback(async () => {
    if (!propinsi) {
      setAlertError({ rc: '', rd: 'SIlahkan Pilih Propinsi Terlebih Dahulu' })
    } else {
      setSearchKotaShow(true)
    }
  }, [propinsi, setSearchKotaShow, setAlertError])

  const closeKotaShow = React.useCallback(async () => {
    setSearchKotaShow(false)
  }, [setSearchKotaShow])

  const onSearchPropinsiChange = React.useCallback(
    (e) => {
      const { value } = e.target
      const newData = _.filter(dataPropinsi, (item) => {
        return _.includes(item.propinsiNama.toLowerCase(), value.toLowerCase())
      })

      setSearchPropinsi(value)
      setDataPropinsiFiltered(newData)
    },
    [dataPropinsi, setSearchPropinsi, setDataPropinsiFiltered]
  )

  const onSearchKotaChange = React.useCallback(
    (e) => {
      const { value } = e.target
      const newData = _.filter(dataKota, (item) => {
        return _.includes(item.kotaNama.toLowerCase(), value.toLowerCase())
      })

      setSearchKota(value)
      setDataKotaFiltered(newData)
    },
    [dataKota, setSearchKota, setDataKotaFiltered]
  )

  const onSearchProdukChange = React.useCallback(
    (e) => {
      const { value } = e.target
      const newData = _.filter(dataProduk, (item) => {
        return _.includes(item.produk.toLowerCase(), value.toLowerCase())
      })

      setSearchProduk(value)
      setDataProdukFiltered(newData)
    },
    [dataProduk, setSearchProduk, setDataProdukFiltered]
  )

  const onCustomerChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setCustomer(value)
    },
    [setCustomer]
  )

  const onNoTelpChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setNotelp(Formatter.number(value))
    },
    [setNotelp]
  )

  const onAlamatChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setAlamat(value)
    },
    [setAlamat]
  )

  const onDonasiChange = React.useCallback(
    (e) => {
      const { value } = e.target
      setDonasi(value)
    },
    [setDonasi]
  )

  const onProdukChange = React.useCallback(
    (e) => {
      const { target, label } = e.currentTarget.dataset
      setProduk(target)
      setProdukLabel(label)
      setSearchProdukShow(false)

      const [selectedProduk] = _.filter(dataProduk, { idproduk: target })
      setProdukHarga(parseInt(selectedProduk.hargajual, 10))

      setIsInqWeb(false)
      setDataRes(null)
    },
    [
      dataProduk,
      setProduk,
      setProdukLabel,
      setSearchProdukShow,
      setProdukHarga,
      setIsInqWeb,
      setDataRes,
    ]
  )

  const onPropinsiChange = React.useCallback(
    (e) => {
      const { target, label } = e.currentTarget.dataset
      setPropinsi(target)
      setPropinsiLabel(label)
      setSearchPropinsiShow(false)
      setKota('')
      setKotaLabel('')
      setSearchKota('')

      const newDataKota = _.filter(dataKotaAll, { propinsiId: target })
      setDataKota(newDataKota)
      setDataKotaFiltered(newDataKota)
    },
    [
      dataKotaAll,
      setPropinsi,
      setPropinsiLabel,
      setSearchPropinsiShow,
      setDataKota,
      setDataKotaFiltered,
      setSearchKota,
    ]
  )

  const onKotaChange = React.useCallback(
    (e) => {
      const { target, label } = e.currentTarget.dataset
      setKota(target)
      setKotaLabel(label)
      setSearchKotaShow(false)
    },
    [setKota, setKotaLabel, setSearchKotaShow]
  )

  const handleClear = React.useCallback(
    (e) => {
      const { target } = e.currentTarget.dataset
      if (target === 'customer') {
        setCustomer('')
      }
      if (target === 'notelp') {
        setNotelp('')
      }
      if (target === 'alamat') {
        setAlamat('')
      }
      if (target === 'donasi') {
        setDonasi('0')
      }

      setIsInqWeb(false)
      setDataRes(null)
    },
    [setCustomer, setNotelp, setAlamat, setDonasi, setIsInqWeb, setDataRes]
  )

  const onProcess = React.useCallback(async () => {
    if (!produk) {
      setAlertError({ rc: '', rd: 'Pilih Produk Zakat Terlebih Dahulu' })
      return false
    }

    if (!customer) {
      setAlertError({ rc: '', rd: 'Masukkan Nama Terlebih Dahulu' })
      return false
    }

    if (!notelp) {
      setAlertError({ rc: '', rd: 'Masukkan Nomor Telepon Terlebih Dahulu' })
      return false
    }

    if (produkHarga === 0 && parseInt(donasi, 10) <= 0) {
      setAlertError({ rc: '', rd: 'Donasi harus diatas 0' })
      return false
    }

    const recaptchaToken = await recaptchaRef.current.executeAsync()
    recaptchaRef.current.reset()

    const responseInq = await dispatch(
      rumahZakatInq(
        userId,
        userPin,
        GROUP_PRODUK,
        produk,
        customer,
        notelp,
        alamat,
        propinsi,
        kota,
        donasi,
        voucherQty,
        recaptchaToken
      )
    )
    if (responseInq.isOK()) {
      const dataRespon = responseInq.getData()
      const { amount } = dataRespon.includes
      const { admin } = dataRespon.includes
      const total =
        parseInt(amount, 0) + parseInt(admin, 0) + parseInt(dataRespon.additional_datas[93], 10)
      if (Math.round(parseFloat(appUserBalance)) >= total) {
        setIsSaldoOk(true)
      }
      setDataRes(dataRespon)
      if (isMobile) {
        setTagihanModalShow(true)
      } else {
        setIsInqWeb(true)

        // eslint-disable-next-line no-unused-expressions
        inqZakatRef.current?.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      setAlertError({ rc: responseInq.getRC(), rd: responseInq.getRD() })
    }

    return true
  }, [
    dispatch,
    userId,
    userPin,
    appUserBalance,
    produkHarga,
    produk,
    customer,
    notelp,
    alamat,
    propinsi,
    kota,
    donasi,
    voucherQty,
    setIsInqWeb,
    setIsSaldoOk,
    setAlertError,
  ])

  const redirectToDeposit = React.useCallback(() => {
    history.push('/deposit')
  }, [history])

  const confirmPayment = React.useCallback(async () => {
    if (!appUserId) {
      setTagihanModalShow(false)
      setLoginModalShow(true)
    } else if (isSaldoOk) {
      const dataIncludes = dataRes.includes
      const { amount, admin } = dataIncludes
      const vcrQty = dataIncludes.voucher_qty
      const reffId = dataIncludes.reff_id

      const responsePay = await dispatch(
        rumahZakatPay(
          userId,
          userPin,
          vcrQty,
          GROUP_PRODUK,
          produk,
          customer,
          notelp,
          alamat,
          propinsi,
          kota,
          amount,
          admin,
          reffId
        )
      )
      if (responsePay.isOK()) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'zakat',
            parentPath: location.pathname,
            title: 'Zakat',
            icon: 'hand-holding-heart',
            transaksi: responsePay.data.includes.reff_id,
            trxDate: responsePay.data.transmission_datetime,
            trxTime: responsePay.data.transmission_datetime,
            total:
              Number(responsePay.data.includes.amount) +
              Number(responsePay.data.includes.admin) +
              parseInt(responsePay.data.additional_datas[93], 10),
            customerId: responsePay.data.includes.customer_id_1,
            discountInfo: responsePay.data.discountInfo,
            info: 'sukses',
          },
        })
      } else if (
        responsePay.getResponseCode() === '' ||
        responsePay.getRC() === '68' ||
        responsePay.getRC().toString() === responsePay.data.includes.reff_id.toString()
      ) {
        history.push({
          pathname: '/struk',
          state: {
            parent: 'zakat',
            parentPath: location.pathname,
            title: 'Zakat',
            icon: 'hand-holding-heart',
            transaksi: responsePay.data.includes.reff_id,
            trxDate: responsePay.data.transmission_datetime,
            trxTime: responsePay.data.transmission_datetime,
            total:
              Number(responsePay.data.includes.amount) +
              Number(responsePay.data.includes.admin) +
              parseInt(responsePay.data.additional_datas[93], 10),
            customerId: responsePay.data.includes.customer_id_1,
            discountInfo: responsePay.data.discountInfo,
            info: 'pending',
          },
        })
      } else {
        setAlertError({ rc: responsePay.getRC(), rd: responsePay.getRD() })
      }
    } else {
      redirectToDeposit()
    }
  }, [
    dispatch,
    history,
    location,
    isSaldoOk,
    appUserId,
    userId,
    userPin,
    produk,
    customer,
    notelp,
    alamat,
    propinsi,
    kota,
    dataRes,
    setAlertError,
    redirectToDeposit,
  ])

  React.useEffect(() => {
    DOMHelper.mountClass('layanan-layout')
    HookHelper.resetLoading()

    if (!appProdukTagihan) {
      setLoadingGetProduk(true)

      dispatch(listProdukTagihan(userId)).then((response) => {
        if (response.isOK()) {
          const msgData = response.getResultAsObject()

          const [dataProdukCc] = Object.values(msgData).filter(
            (g) => `${g.provider.toUpperCase()}` === GROUP_PRODUK
          )

          if (dataProdukCc) {
            const lsProducts = _.orderBy(
              dataProdukCc.products,
              [(obj) => parseInt(obj.urutan, 10), 'namaproduk'],
              ['asc', 'asc']
            )

            setDataProduk(lsProducts)
            setDataProdukFiltered(lsProducts)
          }
        } else {
          setAlertError({
            rc: response.getRC(),
            rd: `${response.getRD()}. \nHarap refresh halaman`,
          })
        }
        setLoadingGetProduk(false)
      })
    } else {
      const [dataProdukCc] = Object.values(appProdukTagihan).filter(
        (g) => `${g.provider.toUpperCase()}` === GROUP_PRODUK
      )

      if (dataProdukCc) {
        const lsProducts = _.orderBy(
          dataProdukCc.products,
          [(obj) => parseInt(obj.urutan, 10), 'namaproduk'],
          ['asc', 'asc']
        )

        setDataProduk(lsProducts)
        setDataProdukFiltered(lsProducts)
      }
    }

    if (appPropinsi) {
      const listPropinsi = []
      const splitAppPropinsi = appPropinsi.split('#')

      if (splitAppPropinsi.length > 0) {
        // eslint-disable-next-line array-callback-return
        splitAppPropinsi.map((item) => {
          const itemPropinsi = item.split(';')
          listPropinsi.push({ propinsiId: itemPropinsi[0], propinsiNama: itemPropinsi[1] })
        })
      }

      setDataPropinsi(listPropinsi)
      setDataPropinsiFiltered(listPropinsi)
    }

    if (appKota) {
      const listKota = []
      const splitKota = appKota.split('#')

      if (splitKota.length > 0) {
        // eslint-disable-next-line array-callback-return
        splitKota.map((item) => {
          const itemKota = item.split(';')
          listKota.push({ propinsiId: itemKota[0], kotaId: itemKota[1], kotaNama: itemKota[2] })
        })
      }

      setDataKotaAll(listKota)
    }

    return () => DOMHelper.unmountClass(['layanan-layout'])
  }, [
    dispatch,
    appUserId,
    userId,
    userPhone,
    appProdukTagihan,
    appPropinsi,
    appKota,
    setDataPropinsi,
    setDataPropinsiFiltered,
    setDataKotaAll,
    setDataProduk,
    setDataProdukFiltered,
    setAlertError,
  ])

  return (
    <Container fluid className="layanan-layout">
      {!appUserIsEmbed && (
        <Row className="header">
          <Col>
            <HeaderComponent title="Zakat" onToggle={toggleSidebar} />
          </Col>
        </Row>
      )}

      <Row>
        {!appUserIsEmbed && (
          <Col
            md={2}
            className={classNames('col-sidebar', { 'is-open': isOpen, 'is-mobile': isMobile })}
          >
            <SidebarComponent isOpen={isOpen} />
          </Col>
        )}

        <Col
          md={{
            span: appUserIsEmbed ? 12 : isOpen ? 10 : 12,
            offset: appUserIsEmbed ? 0 : isOpen ? 2 : 0,
          }}
        >
          <Jumbotron fluid className="banner">
            <Row>
              <Col>
                <Row>
                  <span className={classNames('banner-title', { 'is-mobile': isMobile })}>
                    <FontAwesomeIcon icon="hand-holding-heart" fixedWidth className="mr-3" />
                    Zakat
                  </span>
                </Row>
              </Col>

              {!appUserId ? (
                <Col lg={3} xs={4} md={3} style={{ margin: 'auto' }}>
                  <Button
                    className={classNames('button-login', { 'is-mobile': isMobile })}
                    type="button"
                    onClick={openLoginModal}
                  >
                    Daftar / Login
                  </Button>
                </Col>
              ) : (
                <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                  <Row>
                    <Col lg={10} xs={10} md={10}>
                      <div className="banner-saldo">Saldo Anda</div>
                      <div className="banner-nominal">
                        Rp. {Formatter.price(Math.round(parseFloat(appUserBalance)))}
                      </div>
                    </Col>
                    <Col lg={2} xs={2} md={2}>
                      <span className="banner-title">
                        <FontAwesomeIcon icon="wallet" fixedWidth className="mr-3" />
                      </span>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Jumbotron>

          <div className="content-layanan">
            <Row className="justify-content-md-center">
              <Col lg={isInqWeb ? 7 : 8} xs={12} md={isInqWeb ? 7 : 8} className="bd-form">
                <Form className="form-pelanggan single">
                  {loadingGetProduk && (
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ alignSelf: 'center' }}
                      variant="primary"
                    />
                  )}
                  <Form.Group>
                    <Form.Label className="form-label start">Penyedia Layanan</Form.Label>
                    <InputGroup className="mb-12">
                      <Form.Control plaintext readOnly defaultValue="Rumah Zakat" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="form-label start">Pilih Produk Zakat</Form.Label>
                    <InputGroup
                      className="mb-12"
                      onClick={openProdukShow}
                      style={{ cursor: 'pointer' }}
                    >
                      <Form.Control
                        placeholder="---Pilih Produk Zakat---"
                        value={produkLabel}
                        readOnly
                        style={{ cursor: 'pointer' }}
                      />
                      <InputGroup.Append>
                        <FontAwesomeIcon icon="chevron-down" className="form-icon single" />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="form-label start">Nama</Form.Label>
                    <InputGroup className="mb-12">
                      <Form.Control
                        placeholder="Masukkan Nama"
                        value={customer}
                        onChange={onCustomerChange}
                      />
                      <InputGroup.Append className="flex align-items-center">
                        <Image
                          className={classNames('form-icon-btn', { 'ic-mobile': isMobile })}
                          src="/icon/clear.png"
                          data-target="customer"
                          onClick={handleClear}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="form-label start">Nomor Telepon</Form.Label>
                    <InputGroup className="mb-12">
                      <Form.Control
                        placeholder="Masukkan Nomor Telepon"
                        value={Formatter.serial(notelp)}
                        onChange={onNoTelpChange}
                      />
                      <InputGroup.Append className="flex align-items-center">
                        <Image
                          className={classNames('form-icon-btn', { 'ic-mobile': isMobile })}
                          src="/icon/clear.png"
                          data-target="notelp"
                          onClick={handleClear}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="form-label start">Alamat</Form.Label>
                    <InputGroup className="mb-12">
                      <Form.Control
                        placeholder="Masukkan Alamat"
                        value={alamat}
                        onChange={onAlamatChange}
                      />
                      <InputGroup.Append className="flex align-items-center">
                        <Image
                          className={classNames('form-icon-btn', { 'ic-mobile': isMobile })}
                          src="/icon/clear.png"
                          data-target="alamat"
                          onClick={handleClear}
                        />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="form-label start">Propinsi</Form.Label>
                    <InputGroup
                      className="mb-12"
                      onClick={openPropinsiShow}
                      style={{ cursor: 'pointer' }}
                    >
                      <Form.Control
                        placeholder="---Pilih Propinsi---"
                        value={propinsiLabel}
                        readOnly
                        style={{ cursor: 'pointer' }}
                      />
                      <InputGroup.Append>
                        <FontAwesomeIcon icon="chevron-down" className="form-icon single" />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="form-label start">Kota/Kab</Form.Label>
                    <InputGroup
                      className="mb-12"
                      onClick={openKotaShow}
                      style={{ cursor: 'pointer' }}
                    >
                      <Form.Control
                        placeholder="---Pilih Kota/Kab---"
                        value={kotaLabel}
                        readOnly
                        style={{ cursor: 'pointer' }}
                      />
                      <InputGroup.Append>
                        <FontAwesomeIcon icon="chevron-down" className="form-icon single" />
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  {produkSetDonasi.includes(produk) && (
                    <Form.Group>
                      <Form.Label className="form-label start">Jumlah Donasi</Form.Label>
                      <InputGroup className="mb-12">
                        <Form.Control
                          placeholder="Masukkan Jumlah Donasi"
                          value={Formatter.price(donasi)}
                          onChange={onDonasiChange}
                        />
                        <InputGroup.Append className="flex align-items-center">
                          <Image
                            className={classNames('form-icon-btn', { 'ic-mobile': isMobile })}
                            src="/icon/clear.png"
                            data-target="donasi"
                            onClick={handleClear}
                          />
                        </InputGroup.Append>
                      </InputGroup>
                    </Form.Group>
                  )}
                </Form>
                <Row className="row-proses">
                  <Col className="nominal-minimal" />
                  <Col style={{ margin: 'auto' }}>
                    {!loadingGetProduk && (
                      <>
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_GRECAPTCHA_SITE_KEY}
                          size="invisible"
                          ref={recaptchaRef}
                        />
                        <Button
                          className="btn-process"
                          size="lg"
                          block
                          onClick={onProcess}
                          disabled={appIsLoading}
                        >
                          {appIsLoading && !isMobile && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          {appIsLoading && !isMobile ? ' Mohon Tunggu...' : 'Lanjutkan'}
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
              {isInqWeb && (
                <>
                  <Col md="auto" />
                  <Col lg={4} xs={12} md={4} className="bd-form-inq" ref={inqZakatRef}>
                    <Row className="justify-content-md-center">
                      <Col className="inq-header txt-bold">
                        {dataRes ? `${dataRes.biller_info.productName}` : 'Zakat'}
                      </Col>
                    </Row>
                    {dataRes &&
                      dataRes.strukMaps.map((val) => (
                        <Row key={Formatter.upperFirst(Object.keys(val)[0])}>
                          <Col>{Formatter.upperFirst(Object.keys(val)[0])}</Col>
                          <Col className="inq-end-label">{val ? val[Object.keys(val)[0]] : ''}</Col>
                        </Row>
                      ))}
                    <Row className="justify-content-md-center inq-body-last">
                      <Col className="inq-balance">
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                          <Col lg={2} xs={2} md={2}>
                            <Image src="/img/sc.png" roundedCircle />
                          </Col>
                          <Col lg={5} xs={5} md={5}>
                            Saldo Speedcash
                          </Col>
                          <Col lg={5} xs={5} md={5} className="inq-end-label txt-bold">
                            {dataRes
                              ? `${Formatter.price(
                                  Math.round(parseFloat(dataRes.includes.balance))
                                )}`
                              : 'Rp 0,-'}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {!isSaldoOk && appUserId && (
                      <Row className="justify-content-md-center inq-body-last">
                        <Col className="inq-balance not-ok">
                          <Row className="txt-bold">
                            Saldo Anda belum mencukupi untuk transaksi ini. Segera Top Up
                          </Row>
                        </Col>
                      </Row>
                    )}
                    <Row className="justify-content-md-center">
                      <Col>
                        <Button
                          className="btn-process"
                          type="button"
                          block
                          onClick={
                            appUserId
                              ? isSaldoOk
                                ? confirmPayment
                                : redirectToDeposit
                              : openLoginModal
                          }
                        >
                          {appIsLoading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          {appIsLoading
                            ? ' Mohon Tunggu...'
                            : appUserId
                            ? isSaldoOk
                              ? 'Bayar Sekarang'
                              : 'Top Up Saldo'
                            : 'Daftar / Login'}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </Col>
      </Row>
      <Alert
        header="Perhatian"
        btnText="OK"
        text={alertError.rd}
        type="error"
        show={!!alertError.rd}
        onClosePress={closeError}
        showBorderBottom
        alertStyles={{ justifyContent: 'center' }}
      />
      <LoginModal show={!!loginModalShow} onHide={closeLoginModal} onConfirm={confirmLoginModal} />
      <ZakatModal
        show={!!tagihanModalShow}
        onHide={closeTagihanModal}
        onConfirm={confirmPayment}
        dataRes={dataRes}
        isSaldoOk={isSaldoOk}
      />
      <ZakatListDialog
        title="Zakat"
        group="rumah_zakat"
        show={!!serachProdukShow}
        onHide={closeProdukShow}
        onConfirm={onProdukChange}
        search={searchProduk}
        onSearchChange={onSearchProdukChange}
        data={dataProdukFiltered}
      />
      <PropinsiDialog
        show={!!searchPropinsiShow}
        onHide={closePropinsiShow}
        onConfirm={onPropinsiChange}
        search={searchPropinsi}
        onSearchChange={onSearchPropinsiChange}
        data={dataPropinsiFiltered}
      />
      <KotaDialog
        show={!!searchKotaShow}
        onHide={closeKotaShow}
        onConfirm={onKotaChange}
        search={searchKota}
        onSearchChange={onSearchKotaChange}
        data={dataKotaFiltered}
      />
    </Container>
  )
}

ZakatScreen.defaultProps = {
  appIsLoading: false,
  appPropinsi: '',
  appKota: '',
  appUserId: '',
  appUserPin: '',
  appUserPhone: '',
  appUserBalance: 0,
  appUserIsEmbed: false,
  appProdukTagihan: null,
}

ZakatScreen.propTypes = {
  appUserId: PropTypes.string,
  appPropinsi: PropTypes.string,
  appKota: PropTypes.string,
  appUserPin: PropTypes.string,
  appUserPhone: PropTypes.string,
  appUserBalance: PropTypes.number,
  appUserIsEmbed: PropTypes.bool,
  appIsLoading: PropTypes.bool,
  appProdukTagihan: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appPropinsi: state.Global[globalConstant.PROPINSI],
    appKota: state.Global[globalConstant.KOTA],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserPin: state.Auth[authConstant.USER_PIN],
    appUserBalance: state.Auth[authConstant.USER_BALANCE],
    appUserIsEmbed: state.Auth[authConstant.USER_IS_EMBED],
    appUserPhone: state.Auth[authConstant.USER_PHONE],
    appProdukTagihan: state.Produk[produkConstant.PRODUK_TAGIHAN],
  }),
  (dispatch) => ({ dispatch })
)(ZakatScreen)
