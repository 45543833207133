import React from 'react'
import { Navbar, Button, Image, Form, Row, Col, InputGroup } from 'react-bootstrap'
import { connect, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import Select from 'react-select'

import PropTypes from 'prop-types'
import DOMHelper from '../../helper/domhelper'
import HookHelper from '../../helper/hookhelper'

import authConstant from '../../store/constant/auth'
import LoginModal from '../dialog/auth/login'

import './header.scss'

const HeaderComponent = ({ title, onToggle, appuserId }) => {
  const history = useHistory()
  const [loginModalShow, setLoginModalShow] = React.useState(false)
  const [dataMenu, setDataMenu] = React.useState([])
  const userMarketer = useSelector((state) => state.Auth[authConstant.USER_IS_MARKETER])

  const openLoginModal = React.useCallback(async () => {
    if (!appuserId) setLoginModalShow(true)
  }, [appuserId])

  const confirmLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
    // window.location.reload()
  }, [])

  const closeLoginModal = React.useCallback(async () => {
    setLoginModalShow('')
  }, [])

  const onSelectMenuChange = React.useCallback(
    (e) => {
      if (e) {
        history.push(e.value)
      }
    },
    [history]
  )

  React.useEffect(() => {
    DOMHelper.mountClass('root-layout')
    HookHelper.resetLoading()

    // -- GET MENU
    fetch('/data/menu_select2.json')
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        if (userMarketer) {
          data.splice(
            23,
            0,
            {
              group: 'Freelance Marketing',
              value: '/fm/report',
              label: 'Rapot FM',
              icon: 'file-alt',
              tags: '',
            },
            {
              group: 'Freelance Marketing',
              value: '/fm/commission',
              label: 'Total Komisi FM',
              icon: 'coins',
              tags: '',
            },
            {
              group: 'Freelance Marketing',
              value: '/fm/companion',
              label: 'Total Teman Kamu',
              icon: 'address-book',
              tags: '',
            },
            {
              group: 'Freelance Marketing',
              value: '/fm/friend',
              label: 'Temanku',
              icon: 'user-friends',
              tags: '',
            },
            {
              group: 'Freelance Marketing',
              value: '/fm/disbursement',
              label: 'Pencairan Komisi',
              icon: 'money-bill-alt',
              tags: '',
            }
          )
        }
        setDataMenu(data)
      })

    return () => DOMHelper.unmountClass(['root-layout'])
  }, [setDataMenu, userMarketer])

  return (
    <>
      <Navbar className="navbar header shadow-sm navbar-light bg-light" expand>
        <Row style={{ width: '100%' }}>
          <Col lg={2} md={2} xs={10} style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              id="btn-sidebar"
              name="btn-sidebar"
              variant="link"
              className="btn-sidebar"
              onClick={onToggle}
              aria-label="Button Sidebar"
            >
              <FontAwesomeIcon icon="bars" />
            </Button>
            {isMobile ? (
              <Form
                inline
                style={{
                  border: '1.5px solid',
                  width: '100%',
                  height: '40px',
                  paddingLeft: '5px',
                  borderRadius: '5px',
                  borderColor: '#CCCCCC',
                  alignContent: 'center',
                }}
              >
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1" aria-label="Cari Menu">
                      <FontAwesomeIcon icon="search" style={{ color: '#CCCCCC' }} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Select
                    className={classNames('basic-single', { 'is-mobile': isMobile })}
                    classNamePrefix="select"
                    placeholder="Cari Menu..."
                    options={dataMenu}
                    isClearable
                    onChange={onSelectMenuChange}
                  />
                </InputGroup>
              </Form>
            ) : (
              <Navbar.Brand href="/">
                <img
                  alt={title}
                  src="/img/speedcash.png"
                  width="150"
                  height="48"
                  className="d-inline-block align-top"
                  style={{ height: '100%' }}
                />
              </Navbar.Brand>
            )}
          </Col>
          <Col
            lg={10}
            md={10}
            xs={2}
            style={{ display: 'flex', paddingRight: '0px', paddingLeft: '0px' }}
          >
            {!isMobile && (
              <Form
                inline
                style={{
                  width: '30%',
                  height: '48px',
                  background: '#F6F8FB',
                  padding: '15px',
                  borderRadius: '100px',
                  alignContent: 'center',
                }}
              >
                <InputGroup style={{ width: '100%' }}>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1" aria-label="Cari Menu">
                      <FontAwesomeIcon icon="search" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Cari Menu..."
                    options={dataMenu}
                    isClearable
                    onChange={onSelectMenuChange}
                  />
                </InputGroup>
              </Form>
            )}
            <Navbar.Collapse className="justify-content-end" style={{ width: '70%' }}>
              <Image
                alt="user"
                src="/img/user.png"
                onClick={openLoginModal}
                roundedCircle
                width={isMobile ? '36' : '48'}
                height={isMobile ? '36' : '48'}
              />
            </Navbar.Collapse>
          </Col>
        </Row>
      </Navbar>
      <LoginModal show={!!loginModalShow} onHide={closeLoginModal} onConfirm={confirmLoginModal} />
    </>
  )
}

HeaderComponent.defaultProps = {
  title: '',
  onToggle: null,
  appuserId: '',
}

HeaderComponent.propTypes = {
  title: PropTypes.string,
  onToggle: PropTypes.func,
  appuserId: PropTypes.string,
}

export default connect(
  (state) => ({
    appuserId: state.Auth[authConstant.USER_ID],
  }),
  (dispatch) => ({ dispatch })
)(HeaderComponent)
