import React from 'react'
import { Button, Image, Row, Col, Accordion, Card } from 'react-bootstrap'
import './accordion.scss'

const AccordionPulsaData = () => {
  return (
    <>
      <Col className="d-flex justify-content-center mt-4 mb-3 p-3">
        <h3 className="text-dark text-justify">
          Pilihan Tepat Jadi Agen Pulsa Dengan Kuota Termurah
        </h3>
      </Col>

      <div style={{ padding: '0px 20px 30px 20px' }}>
        <Row className="justify-content-md-center">
          <Col lg={8} xs={12} md={8} className="content-component">
            <div>
              <Row className="d-flex justify-content-center mt-3">
                <p className="d-block text-justify">
                  Semakin mudah buka{' '}
                  <a
                    href="https://member.speedcash.co.id/isi-pulsa-termurah"
                    rel="dofollow"
                    title="aplikasi agen jual pulsa termurah"
                  >
                    {' '}
                    agen pulsa termurah{' '}
                  </a>
                  lengkap dengan pilihan kuota data internet semua operator seperti telkomsel,
                  indosat, xl, axis, three hingga smartfren cukup dalam 1 aplikasi. Begini cara beli
                  pulsa murah di SpeedCash :
                </p>
              </Row>
              <Row className="d-flex justify-content-start mt-3 mx-2 w-100">
                <ol className="text-justify list-content w-100">
                  <li>akses web pulsa : https://member.speedcash.co.id/isi-pulsa-termurah</li>
                  <li>Masuk menggunakan No HP yang aktif</li>
                  <li>Pilih metode verifikasi (bisa melalui Whatsapp atau SMS)</li>
                  <li>Lanjutkan ke menu pulsa & paket data</li>
                  <li>Masukkan No HP yang akan di isikan pulsa / kuota datanya</li>
                  <li>Lalu akan muncul pilihan pulsa atau paket data</li>
                  <li>Dan pilih pulsa atau kuota data internet yang mau dibeli</li>
                  <li>Jika sudah klik lanjutkan untuk membeli</li>
                </ol>
              </Row>
              <Row className="d-flex justify-content-center" style={{ gap: '30px' }}>
                <Image
                  src="/img/agen-pulsa-termurah.jpg"
                  data-target="agen-pulsa-termurah"
                  width="220px"
                />
                <Image
                  src="/img/aplikasi-jual-pulsa-termurah.jpg"
                  data-target="aplikasi-jual-pulsa-termurah"
                  width="220px"
                />
              </Row>
              <Row className="d-flex justify-content-center mt-3">
                <p className="d-block text-justify">
                  Sudah jadi agen pulsa tapi customer minta struk pembelian ? tenang semua sudah ada
                  solusinya, cukup dengan SpeedCash kamu bisa langsung cetak struk dan custom harga
                  admin.
                </p>
              </Row>
              <Row className="d-flex justify-content-center text-dark mt-3">
                <h4>Simak Video Custom Harga Toko Dan Cetak Struk Langsung Dari Aplikasi</h4>
              </Row>
              <Row className="d-flex justify-content-center mt-3">
                <iframe
                  width="100%"
                  height="480"
                  src="https://www.youtube.com/embed/2TAGvrYu5Mo/"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  frameBorder={0}
                  title="Cara cetak struk dan custom harga"
                />
              </Row>
              <div className="mt-4 w-100 d-flex justify-content-center">
                <h5 className="bold">
                  Untung Lebih Cepat Pakai Aplikasi Jual Pulsa Termurah Disini
                </h5>
              </div>
              <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                <a
                  aria-hidden
                  href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar&listing=agen_pulsa_ppob_termurah"
                  className="mx-1"
                  rel="dofollow"
                  title="aplikasi jual pulsa termurah"
                >
                  <Image
                    src="/img/playstore-speedcash.png"
                    data-target="playstore-speedcash"
                    width="220px"
                    height="60px"
                  />
                </a>
                <a
                  aria-hidden
                  href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                  className="mx-1"
                  rel="dofollow"
                  title="aplikasi jual pulsa termurah"
                >
                  <Image
                    src="/img/appstore-speedcash.png"
                    data-target="appstore-speedcash"
                    width="220px"
                    height="60px"
                  />
                </a>
              </Row>
            </div>
          </Col>

          <Col lg={8} xs={12} md={8} className="accordion-component">
            <Accordion className="m-0 p-0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="button-collapse w-100 text-left"
                  >
                    Agen Pulsa Termurah Solusi Mudah Punya Bisnis Tanpa Modal
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Beragam keuntungan bisa kamu raih hanya dengan 1 aplikasi, ga hanya jual
                        pulsa termurah atau kuota saja tapi pakai SpeedCash untuk menunjang konter
                        kamu bisa juga dapat keuntungan lain karena bisa juga digunakan untuk{' '}
                        <a
                          href="https://member.speedcash.co.id/pln/token-listrik"
                          rel="dofollow"
                          title="token listrik murah 24 jam"
                        >
                          jual token listrik
                        </a>{' '}
                        ,{' '}
                        <a
                          href="https://member.speedcash.co.id/cek-bpjs-kesehatan"
                          rel="dofollow"
                          title="cek tagihan BPJS Kesehatan"
                        >
                          cek dan bayar BPJS kesehatan
                        </a>{' '}
                        , tempat bayar pajak online, hingga bisa digunakan untuk jual tiket kereta
                        api dan tiket pesawat garansi harga paling murah dibanding aplikasi lainnya.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Daftar Jadi Agen Pulsa Di Aplikasi SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Banyak aplikasi agen pulsa termurah yang menawarkan beragam keuntungan,
                        namun kami yakin belum ada aplikasi jual pulsa termurah lengkap serta
                        praktis dan cepat untuk digunakan.
                      </p>
                      <Row className="mt-3 w-100">
                        <ol className="text-justify list-content w-100">
                          <li>
                            Download dulu SpeedCash yang ada di playstore/IOS (untuk pengguna
                            iphone)
                          </li>
                          <li>Jika sudah download, login menggunakan no hp yang aktif</li>
                          <li>
                            <p>
                              Jika sudah masuk ke menu <b>Pulsa</b>
                            </p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/aplikasi-jual-pulsa-termurah.png"
                                data-target="aplikasi-jual-pulsa-termurah"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>Lalu masukkan Nomor HP yang akan diisikan pulsanya</li>
                          <li>
                            <p>JIka sudah lalu akan muncul pilihan nominal pulsa</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/aplikasi-pulsa-murah.png"
                                data-target="aplikasi-pulsa-murah"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            <p>Lanjutkan ke menu pembayaran nampak seperti gambar dibawah</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/tempat-beli-pulsa-termurah.png"
                                data-target="tempat-beli-pulsa-termurah"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                        </ol>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Beli Kuota Data Internet Semua Operator (Kecuali Telkomsel)
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk beli kuota internet murah indosat, xl, axis, three, dan smartfren
                        begini langkah mudahnya :
                      </p>
                      <Row className="mt-3 w-100">
                        <ol className="text-justify list-content w-100">
                          <li>
                            Download SpeedCash yang ada di playstore/IOS (untuk pengguna iphone)
                          </li>
                          <li>Jika sudah download, login menggunakan no hp yang aktif</li>
                          <li>
                            <p>
                              Jika sudah masuk ke menu <b>Paket Data</b>
                            </p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/cek-kuota-internet.png"
                                data-target="cek-kuota-internet"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            <p>
                              Pilih operator / provider (indosat, xl, axis, three atau smartfren)
                              JIka sudah lalu akan muncul pilihan nominal pulsa (nampak seperti
                              gambar dibawah)
                            </p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/list-operator-kuota.png"
                                data-target="list-operator-kuota"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            <p>
                              Jika sudah lanjutkan mengisi nomor HP untuk mengisi kuota,nanti
                              setelah di isi akan otomatis keluar pilihan kuota yang mau dibeli
                            </p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/beli-kuota-internet.png"
                                data-target="beli-kuota-internet"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                        </ol>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="4"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Beli Kuota Telkomsel Murah Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Jika cara diatas berlaku untuk pembelian kuota selain telkomel, maka cara
                        ini digunakan khusus pengguna telkomsel, berikut step-stepnya :
                      </p>
                      <Row className="mt-3 w-100">
                        <ol className="text-justify list-content w-100">
                          <li>Buka SpeedCash yang sudah di download</li>
                          <li>
                            Jika sudah download, tapi belum login silahkan daftar dulu menggunakan
                            no hp yang aktif
                          </li>
                          <li>
                            <p>
                              Lanjut dengan masuk ke menu <b>Paket Data</b>
                            </p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/cek-kuota-internet.png"
                                data-target="cek-kuota-internet"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            <p>Pilih operator telkomsel</p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/list-operator-kuota.png"
                                data-target="list-operator-kuota"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            <p>
                              JIka sudah lalu akan muncul pilihan seperti : paket data promo, paket
                              data nasional dan juga data voucher
                            </p>

                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/cara-isi-kuota-telkomsel.png"
                                data-target="cara-isi-kuota-telkomsel"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            <p>
                              Lalu lanjutkan dengan masukan no telkomsel yang mau isi paket data
                              internet
                            </p>
                            <Row className="d-flex justify-content-center">
                              <Image
                                src="/img/beli-kuota-internet.png"
                                data-target="beli-kuota-internet"
                                width="220px"
                                height="400px"
                              />
                            </Row>
                          </li>
                          <li>
                            Selanjutnya akan muncul pilihan paket internet harian, mingguan hingga
                            bulanan
                          </li>
                          <li>Pilih sesuai yang mau dibeli dan lanjutkan dengan klik bayar</li>
                        </ol>
                      </Row>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="5"
                    className="button-collapse w-100 text-left"
                  >
                    Mengapa Usaha Konter Agen Pulsa Masih Diminati ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Beberapa alasan kenapa bisnis agen pulsa masih banyak peminatnya, hal ini
                        tentu saja dengan keberadaan pengguna telepon seluler yang semakin tahun
                        makin meningkat tentunya mereka membutuhkan pulsa apalagi kuota internet.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Dan yang terpenting usaha agen pulsa tidak seperti jual makanan atau minuman
                        yang ada masa expired,karena pulsa merupakan barang yang tidak berwujud
                        sehingga pulsa yang tidak akan expired
                      </p>
                      <p className="d-block text-justify mt-1">
                        Yang terakhir keuntungan pakai aplikasi agen pulsa termurah sudah tentu
                        sangat melimpah, sekarang coba kamu hitung jika dalam 1 hari mampu menjual
                        pulsa nominal 10.000 sebanyak 100x dengan kentungan 500 rupiah setiap
                        transaksi, maka tinggal dikalikan saja 500 rupiah X 100 transaksi maka
                        keuntungan dari nominal 10.000 bisa 50.000.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Itu belum termasuk dari nominal pulsa lain loh! jadi silahkan hitung sendiri
                        keuntungan dari agen pulsa, apalagi jika mendapatkan harga pulsa paling
                        murah.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="6"
                    className="button-collapse w-100 text-left"
                  >
                    Keuntungan Jualan Pulsa Pakai SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Sekali dayung dua tiga pulsa terlampui, itulah kiasan positif jika pakai
                        aplikasi{' '}
                        <a
                          href="https://www.speedcash.co.id/"
                          rel="dofollow"
                          title="dompet digital indonesia"
                        >
                          SpeedCash
                        </a>{' '}
                        untuk jual pulsa murah, karena semua pembayaran digital PPOB (payment point
                        online bank) juga bisa kamu lakukan dalam 1 aplikasi. Selain bisa custom
                        harga dan cetak struk langsung tentunya kamu juga otomatis bisa pakai fitur
                        menarik lainnya seperti untuk : top up ff, top up ml, tiket kereta -
                        pesawat, dan pembayaran tagihan bulanan lainya.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="7"
                    className="button-collapse w-100 text-left"
                  >
                    Syarat Dan Ketentuan Agen Pulsa & Kuota Termurah Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Dengan memakai SpeedCash baik sebagai agen pulsa atau dipakai sendiri maka
                        sudah tentu pengguna telah dianggap menerima, menyetujui. paham dan sepakat
                        mengenai isi di syarat dan ketentuan berikut :
                      </p>
                      <ol className="text-justify list-content">
                        <li>
                          Wajib pastikan nomor telepon yang dimasukkan sudah tepat dan merupakan
                          nomor prabayar yang masih masa aktif.
                        </li>
                        <li>
                          Selanjutnya harus memiliki akun SpeedCash, karena pembelian pulsa hanya
                          dapat dilakukan oleh pengguna yang sudah terdaftar di akun SpeedCash.
                        </li>
                        <li>
                          Untuk kamu yang belum pernah punya akun SpeedCash, kamu bisa lakukan
                          pembelian sebagai pengguna baru. Tanpa ada ikatan khusus sehingga kamu
                          bisa top up saldo SpeedCash kapanpun sesuai dengan kebutuhan
                        </li>
                        <li>
                          akun yang digunakan untuk membeli pulsa wajib sudah melakukan verifikasi
                          nomor handphone.
                        </li>
                        <li>
                          Jika Kesalahan pengisian nomor tujuan atau nomor ponsel kadaluarsa, maka
                          tidak ada proses pengembalian dana.
                        </li>
                        <li>
                          SpeedCash tidak bertanggung jawab atas kesalahan input nomor HP yang
                          diakibatkan dari kesalahan input nomor tujuan oleh pengguna.
                        </li>
                        <li>
                          Official SpeedCash tidak pernah meminta email dan password ataupun PIN
                          pengguna. Mohon untuk tidak menginfokan data tersebut ke pihak manapun.
                        </li>
                        <li>
                          Harga pulsa maupun kuota dapat berubah sewaktu-waktu tanpa pemberitahuan.
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="10"
                    className="button-collapse w-100 text-left"
                  >
                    Berapa Nominal Pulsa Yang Bisa Dijual Kembali
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ketika memulai usaha agen pulsa tentu hal pertama yang dipertimbangkan ialah
                        nominal pulsa berapa saja yang bisa dijual ke customer / pembeli ? untuk hal
                        tersebut kamu ga perlu repot mikir berapa saja nominal yang tersedia di
                        SpeedCash, karena kami sangat mendukung pebisnis agen pulsa dan kuota
                        sehingga kami sudah merancang detail dan lengkap nominal pulsa beserta harga
                        pulsa.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Untuk nominal pulsa yang ada di SpeedCash sangat beragam mulai dari yang
                        paling rendah 1000 rupiah untuk pulsa tri, telkomsel, xl, axis serta
                        smaartfren tersedia pulsa paling rendah mulai 5000.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Sedangkan untuk nominal pulsa paling tinggi tersedia hingga 1jt untuk pulsa
                        telkomsel, 200 ribu untuk pembelian pulsa tri, 300 ribu pulsa XL dan axis,
                        dan ada juga nominal pulsa 500 ribu untuk smartfren
                      </p>
                      <p className="d-block text-justify mt-1">
                        Untuk menjual pulsa ke konsumen SpeedCash memberikan kebebasan ke para agen
                        pulsa dalam menentukan harga bahkan SpeedCash memfasilitasi dengan ada fitur
                        custom harga dan cetak struk agar para agen pulsa untuk merubah harga total
                        bayar yang diberikan ke konsumen nantinya.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Jangan biarkan pelanggan pulsamu berkurang karena harga pulsa yang kamu jual
                        sangat mahal, sekarang waktu yang tepat upgrade aplikasi agen pulsa murah
                        menggunakan SpeedCash selainya harganya termurah, fiturnya lengkap download
                        dan aktivasinya pun gratis
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="11"
                    className="button-collapse w-100 text-left"
                  >
                    Adakah Ketentuan Lain Sebelum Bergabung Di Agen Pulsa Termurah SpeedCash ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk memakai aplikasi jual pulsa termurah dan kuota terlengkap dari
                        SpeedCash tidak ada ketentuan lain yang mengikat, siapapun bisa jadi agen
                        pulsa murah dan bisa langsung jual kuota pakai SpeedCash tanpa ada syarat
                        dan batas pemakaian
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="12"
                    className="button-collapse w-100 text-left"
                  >
                    Bagaimana Cara Isi Top Up Saldo SpeedCash ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="12">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Sebelum memulai usaha agen pulsa pakai aplikasi jual pulsa murah SpeedCash
                        ada baiknya kamu harus tau gimana cara isi saldonya.
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-center text-dark mt-3">
                      <h4>Lihat Video Mengisi Saldo SpeedCash Ini</h4>
                    </Row>
                    <Row className="d-flex w-100 justify-content-center mb-3">
                      <iframe
                        width="100%"
                        height="480"
                        src="https://www.youtube.com/embed/sI1fLYglLwk"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        frameBorder={0}
                        title="Cara Isi Saldo Di SpeedCash"
                      />
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="13"
                    className="button-collapse w-100 text-left"
                  >
                    Info Sekilas Tentang Pulsa
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="13">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Pulsa adalah alat pembayaran yang digunakan untuk layanan jaringan telepon
                        genggam atau perangkat telekomunikasi yang menggunakan teknologi nirkabel,
                        seperti modem portabel. Jasa layanan ini termasuk sambungan telepon, pesan
                        singkat (SMS), dan koneksi internet. Hanya layanan seluler prabayar yang
                        ditawarkan oleh operator seluler yang dapat digunakan pulsa.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="14"
                    className="button-collapse w-100 text-left"
                  >
                    Lalu Apa Itu Agen Pulsa Termurah
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="14">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Agen pulsa adalah individu atau perusahaan yang menjual pulsa elektrik atau
                        kuota data internet kepada konsumen. Disebut juga sebagai perantara dari
                        penyedia layanan jual pulsa dan kuota ke pengguna akhir dalam hal ini
                        dikatakan sebagai pembeli / customer
                      </p>
                      <p className="d-block text-justify mt-1">
                        Secara harfiah agen pulsa termurah adalah perusahaan tempat dimana para
                        pebisnis jual pulsa dan kuota internet mendapatkan pemasok / suplier pulsa
                        dengan harga yang murah atau bisa disebut dengan harga grosir dan cocok
                        untuk dijual kembali ataupun dipakai sendiri.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="15"
                    className="button-collapse w-100 text-left"
                  >
                    Apakah Aplikasi Agen Pulsa Dan Kuota Termurah Bisa Dipakai Pribadi ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="15">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Ya tentu saja, jika kamu seorang penjual pulsa dan kuota dan ingin beli
                        pulsa untuk dipakai sendiri tentunya ini sangat menguntungkan karena kamu
                        sudah mendapatkan harga paling murah dan beli untuk diri sendiri tentunya
                        tidak ada biaya tambahan lagi
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="16"
                    className="button-collapse w-100 text-left"
                  >
                    Apa Ada Perbedaan Harga Di Aplikasi Dengan Web Pulsa
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="16">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Tentu tidak, harga beli yang tertera di website pulsa dengan aplikasi sama
                        saja tidak ada perbedaan, hanya saja jika kamu ingin lebih cepat dan praktis
                        bisa berjualan pulsa online maka kamu bisa langsung download SpeedCash di
                        Android maupun Playstore keduanya baik web atau aplikasi siap melayani
                        penjualan pulsa online 24 jam
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="17"
                    className="button-collapse w-100 text-left"
                  >
                    Macam-Macam Jenis Pulsa Yang Ada Di SpeedCash
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="17">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Sebagai aplikasi jual pulsa murah yang cocok digunakan para agen pulsa
                        se-indonesia raya, tetntunya kami memberikan banyak pilihan jenis pulsa yang
                        varitif mulai pulsa transfer, pulsa reguler, pulsa telepon hingga pulsa masa
                        aktif saja.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Dengan banyaknya jenis serta nominal pulsa tentunya dibarengin dengan harga
                        pulsa termurah, kami harapkan agen pulsa yang sudah menggunakan SpeedCash
                        bisa lebih untung secara maximal dan terus menggunakan SpeedCash, karena
                        tidak hanya pulsa yang murah saja namun setiap transaksi yang dilakukan akan
                        dapat poin.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="18"
                    className="button-collapse w-100 text-left"
                  >
                    Apa Perbedaan Pulsa Transfer Dengan Reguler
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="18">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Pulsa transfer adalah layanan yang tersedia untuk pengguna telepon seluler
                        berfungsi mengirimkan pulsa dari satu nomor ke nomor lainnya. Penting untuk
                        diketahui bahwa pulsa transfer tidak menambah masa aktif nomor telepon
                        pascabayar.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Karena secara manfaat pulsa transfer hanya untuk mengirimkan sejumlah
                        nominal pulsa ke nomor lain, ini sangat berguna ketika seseorang membutuhkan
                        pulsa tetapi tidak dapat membelinya sendiri.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Sedangkan pulsa reguler bisa menambah masa aktif, sehingga kamu bisa pilih /
                        menawarkan ke customer mana jenis pulsa yang ingin dibeli pulsa reguler atau
                        pulsa transfer ? karena secara fungsi keduanya dapat digunakan untuk
                        panggilan suara, mengirim pesan teks (SMS), dan mengakses layanan data
                        internet.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="19"
                    className="button-collapse w-100 text-left"
                  >
                    Lalu Apa Itu Pulsa Masa Aktif ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="19">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Pulsa masa aktif biasanya dibeli orang jika nomor teleponnya sudah memasuki
                        masa tenggang namun pulsa regulernya masih banyak sehingga dengan beli masa
                        aktif pulsa maka usia nomor telepon pascabayarnya jadi lebih panjang lagi.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Dengan membeli pulsa masa aktif artinya juga dapat nominal pulsa juga,
                        semisal sisa pulsa 50.000 namun masa aktif akan berakhir nah pengguna
                        telepon operator bisa beli pulsa masa aktif 10.000 untuk memperpanjang masa
                        aktifnya, sehingga total pulsa yang dimiliki sebesar 60.000.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="20"
                    className="button-collapse w-100 text-left"
                  >
                    Beda Masa Tenggang Dan Masa Aktif ?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="20">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Masa tenggang adalah waktu tambahan setelah masa aktif pulsa berakhir di
                        mana pengguna masih memiliki kesempatan untuk mengisi ulang pulsa dan
                        memperpanjang masa aktifnya sebelum pulsa benar-benar hangus.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Masa aktif, di sisi lain, adalah waktu di mana pengguna dapat menggunakan
                        pulsa yang mereka miliki untuk melakukan panggilan, mengirim pesan teks,
                        atau menggunakan layanan data. Ini dimulai saat pengisian ulang dilakukan
                        dan berlangsung selama jangka waktu tertentu.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="21"
                    className="button-collapse w-100 text-left"
                  >
                    Cara Beli Kuota Dengan Pulsa
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="21">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Saat ini pemakaian internet sudah mutlak digunakan hampir semua masyarakat
                        karena hampir semua kegiatan dilakukan dengan internet oleh sebab itu kuota
                        internet sangat dibutuhkan.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Salah satu cara untuk mendapatkan kuota internet bisa dengan beli kuota
                        pakai pulsa, dan cara yang paling cepat seperti berikut :
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start mt-3 mx-2 w-100">
                      <ol className="text-justify list-content">
                        <li>Buka SpeedCash yang sudah di unduh </li>
                        <li>Lalu download, login menggunakan no hp yang aktif</li>
                        <li>Lanjutkan masuk ke menu Paket Data</li>
                        <li>Pilih operator / provider (indosat, xl, axis, three atau smartfren)</li>
                        <li>Selanjutnya akan muncul pilihan nominal pulsa</li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="22"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Pulsa Telkomsel
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="22">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Sudah isi pulsa telkomsel namun belum masuk ? tenang, langkah pertama yang
                        harus kamu lakukan ialah cek pulsa telkomsel, begini langkahnya :
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start mt-3 mx-2 w-100">
                      <ul className="text-justify list-content">
                        <li>
                          Kode USSD: Buka aplikasi dialer ponsel Anda, lalu ketikkan *888# dan tekan
                          tombol panggil.
                        </li>
                        <li>Lewat aplikasi MyTelkomsel: Buka aplikasi MyTelkomsel di ponsel.</li>
                        <li>Melalui panggilan suara: Hubungi nomor 188.</li>
                      </ul>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="23"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Sisa Pulsa Indosat
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="23">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Dan untuk penggunaa indosat oreedoo kamu bisa mengecek pulsa pakai cara ini
                        :
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start mt-3 mx-2 w-100">
                      <ul className="text-justify list-content">
                        <li>
                          Melalui USSD: Buka aplikasi dialer ponsel Anda, lalu ketikkan *123# dan
                          tekan tombol panggil.
                        </li>
                        <li>Melalui aplikasi MyIndosat: Buka aplikasi MyIndosat di ponsel.</li>
                        <li>Melalui panggilan suara: Hubungi nomor 185.</li>
                      </ul>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="24"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Pulsa XL Dan Axis
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="24">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Untuk cek sisa pulsa kartu XL dan AXIS hampir sama karena sudah menjadi satu
                        group.
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start mt-3 mx-2 w-100">
                      <ul className="text-justify list-content">
                        <li>
                          Kode USSD: Buka logo telepon di ponsel Anda, lalu ketikkan *808# dan tekan
                          tombol panggil.
                        </li>
                        <li>Melalui aplikasi myXL: Buka aplikasi myXL di ponsel.</li>
                        <li>Lewat panggilan suara: Hubungi nomor 123.</li>
                      </ul>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="25"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Pulsa Kartu Tri 3
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="25">
                  <Card.Body className="card-content">
                    <Row className="d-flex justify-content-start my-1 mx-2 w-100">
                      <li>
                        Melalui USSD: Buka dialer ponsel Anda, lalu ketikkan *111# dan tekan tombol
                        panggil.
                      </li>
                      <li>Melalui aplikasi BimaTri: Buka aplikasi BimaTri di ponsel Anda.</li>
                      <li>Melalui panggilan suara: Hubungi nomor 111.</li>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="26"
                    className="button-collapse w-100 text-left"
                  >
                    Cek Pulsa Smartfren
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="26">
                  <Card.Body className="card-content">
                    <Row className="d-flex justify-content-start my-1 mx-2 w-100">
                      <ul className="text-justify list-content">
                        <li>
                          {' '}
                          Kode USSD : Buka aplikasi dialer telepon Anda, lalu ketikkan *999# dan
                          tekan tombol panggil.
                        </li>
                        <li>
                          Melalui aplikasi MySmartfren: Buka aplikasi MySmartfren di ponsel Anda.
                        </li>
                        <li>Lewat panggilan suara: Hubungi nomor 999.</li>
                      </ul>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="27"
                    className="button-collapse w-100 text-left"
                  >
                    Kendala Saat Isi Pulsa Dan Kuota
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="27">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Mungkin sebagai agen pulsa kamu sering mengalami kendala seperti pulsa yang
                        belum masuk atau kuota internet belum bertambah, namun tenang saat ini
                        SpeedCash semakin memudahkan kamu berjualan pulsa dengan adanya bantuan CS
                        yang siap melayani 24 jam untuk mengatasi kendala saat isi pulsa dan kuota.
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-center text-dark mt-3">
                      <h5>Pulsa Belum Masuk Setelah 1x24 Jam</h5>
                    </Row>
                    <Row>
                      <p className="d-block text-justify">
                        Jika status pembelian masih menunggu konfirmasi operator atau pending dalam
                        waktu 24 jam, silakan hubungi CS SpeedCash melalui live Chat Di aplikasi
                        untuk meminta operator terkait untuk dikonfirmasi. Selain itu, selalu
                        periksa halaman status transaksi untuk mengetahui status pembelian pulsa.
                        Apabila statusnya sudah berhasil dan pulsa telah diterima, maka Anda dapat
                        menggunakannya.
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-center text-dark mt-3">
                      <h5>Pulsa Atau Kuota Belum Bisa Digunakan</h5>
                    </Row>
                    <Row>
                      <p className="d-block text-justify">
                        Pengisian pulsa ke nomor tujuan berhasil namun belum dapat menggunakan
                        pulsa, maka kamu dapat menghubungi pihak operator terkait di nomor berikut
                        ini:
                      </p>
                    </Row>
                    <Row className="d-flex justify-content-start my-1 mx-2 w-100">
                      <ol className="text-justify list-content">
                        <li>
                          <b>Call Center Telkomsel:</b>
                          <ol>
                            <li>
                              Melalui kartu simPATI dan Kartu AS: 155 untuk layanan informasi
                              pelanggan 24 jam (Gratis) dan 188 untuk layanan Contact Center 24 jam
                              (Berbayar)
                            </li>
                            <li>
                              Melalui ponsel dan fixed phone: Nasional: 08071811811, Jakarta: (021)
                              21899811, Bandung: (022) 2553811, Surabaya: (031) 8403811, dan Medan:
                              (061) 4578811
                            </li>
                          </ol>
                        </li>
                        <li>
                          <b>Call Center Indosat</b>
                          <ol>
                            <li>Via PSTN : 021 5438 8888, 021 3000 3000, atau 021 3011 1111</li>
                            <li>Via IM3 : Dial 100 (Rp. 400,-/call)</li>
                            <li>Fax : 021 5449501-06</li>
                          </ol>
                        </li>
                        <li>
                          <b>Call Center XL</b>
                          <ol>
                            <li>Telepon : +62-21-579 59 817</li>
                            <li>818 – Untuk info layanan melalui mesin penjawab (Gratis)</li>
                            <li>
                              817 – Untuk berbicara dengan XL Contact Center Representatives : Rp
                              350,-/per panggilan.
                            </li>
                            <li>Fax : +62-21-579 59808</li>
                            <li>Email : customerservice@xl.co.id</li>
                            <li>Twitter : Follow @MyXLCare</li>
                          </ol>
                        </li>
                        <li>
                          <b>Call Center 3 (Three)</b>
                          <ol>
                            <li>
                              Melalui kartu simPATI dan Kartu AS: 155 untuk layanan informasi
                              pelanggan 24 jam (Gratis) dan 188 untuk layanan Contact Center 24 jam
                              (Berbayar)
                            </li>
                            <li>
                              Melalui ponsel dan fixed phone: Nasional: 08071811811, Jakarta: (021)
                              21899811, Bandung: (022) 2553811, Surabaya: (031) 8403811, dan Medan:
                              (061) 4578811
                            </li>
                          </ol>
                        </li>
                        <li>
                          <b>Call Center Smartfren</b>
                          <ol>
                            <li>
                              Via telepon : 888 dari kartu Smartfren (Rp.300/panggilan) atau
                              08811223344 / 02150100000
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="28"
                    className="button-collapse w-100 text-left"
                  >
                    Perbedaan Nomor Telepon Pascabayar Dan Prabayar
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="28">
                  <Card.Body className="card-content">
                    <Row>
                      <p className="d-block text-justify">
                        Nomor seluler pascabayar dan prabayar adalah dua jenis layanan
                        telekomunikasi yang berbeda, masing-masing memiliki karakteristik dan cara
                        pembayaran yang unik.
                      </p>
                      <p className="d-block text-justify mt-1">
                        Dari segi pembayaran pengguna nomor seluler prabayar wajib isi pulsa atau
                        beli kuota dulu untuk dapat menggunakan fasiltas kartu telepon prabayar.
                        Sedangkan untuk pascabayar pengguna akan membayar tagihan di akhir periode
                        tertentu (biasanya bulanan).
                      </p>
                      <p className="d-block text-justify mt-1">
                        Sekian informasi info bisnis yang menguntungkan yaitu dengan menjadi agen
                        pulsa termurah dan jual kuota internet terlengkap hanya dengan menggunakan 1
                        aplikasi, dapatkan sejuta kemudahan berlapis keuntungan hanya dengan
                        SpeedCash aplikasi jual pulsa termurah di indonesia.
                      </p>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="29"
                    className="button-collapse w-100 text-left"
                  >
                    Siap Menjadi Agen Pulsa Termurah Dan Kuota Terlengkap ? Download Sekarang
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="29">
                  <Card.Body className="card-content">
                    <Row className="d-flex w-100 justify-content-center mb-3 gap-3">
                      <a
                        aria-hidden
                        href="https://play.google.com/store/apps/details?id=com.bm.sc.bebasbayar&listing=agen_pulsa_ppob_termurah"
                        className="mx-1"
                        rel="nofollow"
                        title="jual pulsa termurah"
                      >
                        <Image
                          src="/img/playstore-speedcash.png"
                          data-target="playstore-speedcash"
                          width="220px"
                          height="60px"
                        />
                      </a>
                      <a
                        aria-hidden
                        href="https://apps.apple.com/id/app/speedcash-transfer-ewallet/id6459304122"
                        className="mx-1"
                        rel="nofollow"
                        title="jual pulsa termurah"
                      >
                        <Image
                          src="/img/appstore-speedcash.png"
                          data-target="appstore-speedcash"
                          width="220px"
                          height="60px"
                        />
                      </a>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AccordionPulsaData
