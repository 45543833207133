import React from 'react'

import './promoPln.scss'

const PromoPln = () => {
  const [showTerm, setShowTerm] = React.useState(false)

  const toogleTerm = React.useCallback(() => {
    setShowTerm((prev) => !prev)
  }, [])

  return (
    <div className="wrapper-promo">
      <div>
        <span className="head-title">Promo Resmi PLN</span>
        <ul className="info-list">
          <li>
            Sesuai program dari PLN mulai 1 januari 2025 untuk setiap transaksi Listrik PLN dengan
            daya 2200 VA hingga ke bawah akan mendapatkan diskon 50%!{' '}
            <span aria-hidden className="btn-term" onClick={toogleTerm}>
              S&K Berlaku
            </span>
          </li>
        </ul>
      </div>
      {showTerm && (
        <div>
          <b>S&K :</b>
          <ol className="info-list">
            <li>Program diskon PLN berlaku selama 2 bulan (Januari dan Februari 2025)</li>
            <li>
              Diskon berlaku untuk pelanggan dengan daya 450 VA, 900 VA, 1.300 VA dan 2.200 VA
            </li>
            <li>
              Untuk pelanggan pascabayar diskon tarif listrik 50 persen akan diberikan secara
              otomatis pada pembayaran tagihan untuk pemakaian Januari dan Februari 2025.
            </li>
          </ol>
        </div>
      )}
    </div>
  )
}

export default PromoPln
