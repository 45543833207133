/* eslint-disable no-lonely-if */
import axios from 'axios'
import qs from 'qs'

import Logger from '../helper/logger'
import store from '../store'
import globalConstant from '../store/constant/global'
import authConstant from '../store/constant/auth'
import BaseMessage from './message/base'
import logout from './action/auth/logout'
import Browser from '../helper/browser'
import Var from '../helper/var'

export default class MessageSenderBrowser {
  constructor() {
    axios.defaults.validateStatus = () => {
      return true
    }
    this.tag = ''
    this.isShowLoading = true

    this.instance = axios.create({
      baseURL: Browser.baseURL(),
      responseType: 'json',
      timeout: 60000,
      headers: {
        post: {
          'Content-Type': 'application/json',
        },
      },
    })
    this.instance.interceptors.request.use(this.onRequestSuccess, this.onRequestError)
    this.instance.interceptors.response.use(this.onResponseSuccess, this.onResponseError)
  }

  showLoading(show) {
    this.isShowLoading = show

    return this
  }

  onRequestSuccess = (config) => {
    Logger.log(`REQUEST ${config.method.toUpperCase()} TO`, config.url)
    if (Logger.IS_DEEP_TRACE) {
      Logger.log(`REQUEST HEADER ${this.tag}`, config.headers)
    }

    if (config.method === 'get') {
      Logger.log(`REQUEST DATA ${this.tag}`, JSON.stringify(config.params))
    } else {
      Logger.log(`REQUEST DATA ${this.tag}`, config.data)
    }

    return config
  }

  onRequestError = (error) => {
    Logger.error(`REQUEST ERROR OCCURED ${this.tag}`, error)

    if (this.isShowLoading) {
      store.dispatch({
        type: globalConstant.IS_LOADING,
        payload: false,
      })
    }
    return Promise.reject(error)
  }

  onResponseSuccess = (response) => {
    if (Logger.IS_DEEP_TRACE) {
      Logger.log(`RESPONSE HEADER ${this.tag}`, response.headers)
    }
    Logger.log(`RESPONSE DATA ${this.tag}`, response.data)

    let msg
    if (response.status >= 200 && response.status <= 299) {
      msg = new BaseMessage(response.data)
    } else {
      msg = new BaseMessage()
      msg.setRC(`${response.status}`)
      msg.setRD(response.statusText)
      if (response.data) {
        if (response.data.error) {
          msg.setError(response.data.error)
          msg.setErrorMessage(response.data.error_description)
        }
      }
    }

    if (this.isShowLoading) {
      store.dispatch({
        type: globalConstant.IS_LOADING,
        payload: false,
      })
    }
    return msg
  }

  onResponseError = (error) => {
    Logger.error(`RESPONSE ERROR OCCURED ${this.tag}`, error)

    const msg = new BaseMessage()
    msg.setRC('GE')
    msg.setRD(error)

    if (this.isShowLoading) {
      store.dispatch({
        type: globalConstant.IS_LOADING,
        payload: false,
      })
    }
    return Promise.resolve(msg)
  }

  async doGet(msg, retry) {
    if (this.isShowLoading) {
      store.dispatch({
        type: globalConstant.IS_LOADING,
        payload: true,
      })
    }
    this.tag = ` OF ${msg.getPath()}`

    const { Auth } = store.getState()
    const headers = msg.getHeader()

    if (Auth[authConstant.USER_ID]) {
      headers.Authorization = `Bearer ${Auth[authConstant.USER_IN_ACCESS_TOKEN]}`
    } else {
      headers.Authorization = `Bearer ${process.env.REACT_APP_PASSWORD_TOKEN}`
    }

    let response = await this.instance.get(msg.getPath(), {
      params: msg.getData(),
      headers,
    })
    if (response.getRC() === '401') {
      if (Auth[authConstant.USER_ID]) {
        store.dispatch(logout())
      } else {
        const isSuccess = await this.doRetryToken()
        if (isSuccess && Var.toInt(retry) < 3) {
          response = await this.doGet(msg, Var.toInt(retry) + 1)
        }
      }
    }

    return response
  }

  async doPost(msg, retry) {
    if (this.isShowLoading) {
      store.dispatch({
        type: globalConstant.IS_LOADING,
        payload: true,
      })
    }

    this.tag = ` OF ${msg.getPath()}`

    const { Auth, Global } = store.getState()
    const headers = msg.getHeader()
    headers['x-csrf-token'] = Global[globalConstant.CSRF_TOKEN]

    if (msg.getPath() === 'red-velvet/motp/user/check') {
      if (Auth[authConstant.USER_ACCESS_TOKEN]) {
        headers.Authorization = `Bearer ${Auth[authConstant.USER_ACCESS_TOKEN]}`
      }
    } else {
      if (Auth[authConstant.USER_ID]) {
        if (Auth[authConstant.USER_IN_ACCESS_TOKEN]) {
          headers.Authorization = `Bearer ${Auth[authConstant.USER_IN_ACCESS_TOKEN]}`
        }
      } else if (Auth[authConstant.USER_ACCESS_TOKEN]) {
        headers.Authorization = `Bearer ${Auth[authConstant.USER_ACCESS_TOKEN]}`
      } else {
        headers.Authorization = `Bearer ${process.env.REACT_APP_PASSWORD_TOKEN}`
      }
    }

    let data = msg.getData()
    if (msg.getContentType() === BaseMessage.FORM) {
      data = qs.stringify(data, { arrayFormat: 'brackets' })
    }

    data = JSON.stringify(data)
    // console.log('dataencrppt', data)

    let response = await this.instance.post(msg.getPath(), data, {
      headers,
    })
    if (response.data.err === 'invalid_grants') {
      await store.dispatch({
        type: authConstant.USER_ACCESS_TOKEN,
        payload: process.env.REACT_APP_PASSWORD_TOKEN,
      })

      response = await this.doPost(msg, 1)
    }
    if (response.getRC() === '401') {
      if (Auth[authConstant.USER_ID]) {
        store.dispatch(logout())
      } else {
        const isSuccess = await this.doRetryToken()
        if (isSuccess && Var.toInt(retry) < 3) {
          response = await this.doPost(msg, Var.toInt(retry) + 1)
        }
      }
    }
    if (response.getRC() === '403') {
      const isSuccess = await this.doRetryCsrfToken()
      if (isSuccess && Var.toInt(retry) < 3) {
        response = await this.doPost(msg, Var.toInt(retry) + 1)
      }
    }

    return response
  }

  async doRetryToken() {
    // const data = 'grant_type=client_credentials'
    const data = JSON.stringify({ grant_type: 'client_credentials' })
    const response = await this.instance.post('api/oauth/token', data, {
      auth: {
        username: process.env.REACT_APP_CLIENT_ID,
        password: process.env.REACT_APP_CLIENT_SECRET,
      },
    })

    const token = response.getItem('access_token')

    await store.dispatch({
      type: authConstant.USER_ACCESS_TOKEN,
      payload: token,
    })

    return !!token
  }

  async doRetryAccessToken() {
    // const data = 'grant_type=client_credentials'
    const data = JSON.stringify({ grant_type: 'client_credentials' })
    const response = await this.instance.post('api/oauth/token', data, {
      auth: {
        username: process.env.REACT_APP_CLIENT_ID,
        password: process.env.REACT_APP_CLIENT_SECRET,
      },
    })

    const token = response.getItem('access_token')

    await store.dispatch({
      type: authConstant.USER_ACCESS_TOKEN,
      payload: token,
    })

    return !!token
  }

  // static normalizeResponse(response) {
  //   if (response instanceof BaseMessage) {
  //     return response
  //   }

  //   const msg = new BaseMessage()
  //   msg.setRC('GE')
  //   msg.setRD('GE')

  //   return msg
  // }

  async doRetryCsrfToken() {
    const senderCsrf = axios.create({
      baseURL: Browser.baseURL(),
      responseType: 'json',
      timeout: 60000,
    })

    this.tag = ' OF /csrf'

    senderCsrf.interceptors.request.use(this.onRequestSuccess, this.onRequestError)
    senderCsrf.interceptors.response.use(this.onResponseSuccess, this.onResponseError)

    const response = await senderCsrf.get('/csrf')
    const token = response.getItem('csrf_token')

    await store.dispatch({
      type: globalConstant.CSRF_TOKEN,
      payload: token,
    })

    return !!token
  }
}
