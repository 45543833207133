/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import {
  Container,
  Jumbotron,
  Row,
  Col,
  Image,
  Button,
  Card,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from 'react-popup-alert'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import DOMHelper from '../../helper/domhelper'
import HookHelper from '../../helper/hookhelper'
import Formatter from '../../helper/formatter'
import DateTime from '../../helper/datetime'
import Browser from '../../helper/browser'
import logout from '../../api/action/auth/logout'

import './home.scss'
import globalConstant from '../../store/constant/global'
import authConstant from '../../store/constant/auth'
import produkConstant from '../../store/constant/produk'
import SidebarComponent from '../component/sidebar'
import HeaderComponent from '../component/header'
import LoginModal from '../dialog/auth/login'
import DepositAktifModal from '../dialog/deposit/depositAktif'
import RedirrectModal from '../dialog/redirrect'

import {
  listProdukTagihan,
  globalProduk,
  getSliderPromo,
  refreshSaldo,
} from '../../api/action/global'
import { depositList } from '../../api/action/deposit/deposit'
import { resumeTrxDaily, resumeTrxMonth } from '../../api/action/laporan/report_trx'

const HomeScreen = ({
  appUserId,
  appUserPin,
  appSettingMenu,
  appUserBalance,
  appUserCampaign,
  appUserIsEmbed,
  appProdukTagihan,
  dispatch,
}) => {
  const history = useHistory()
  const [userId] = React.useState(!appUserId ? process.env.REACT_APP_USER_DEMO_ID : appUserId)
  const [userPin] = React.useState(
    !appUserId ? process.env.REACT_APP_USER_DEMO_PASSWORD : appUserPin
  )
  const [isOpen, setIsOpen] = React.useState(!isMobile)
  const [alertError, setAlertError] = React.useState('')
  const [loginModalShow, setLoginModalShow] = React.useState(false)
  const [depositAktifModal, setDepositAktifModal] = React.useState(false)
  const [redirrectModalShow, setRedirrectModalShow] = React.useState(false)
  const [isRefreshSaldo, setRefreshSaldo] = React.useState(false)
  const [depositAktif, setDepositAktif] = React.useState([])
  const [resumeDaily, setResumeDaily] = React.useState('')
  const [resumeMonthly, setResumeMonthly] = React.useState('')
  const [sliderPromo, setSliderPromo] = React.useState([])

  const toggleSidebar = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])
  const closeError = React.useCallback(() => setAlertError(''), [])

  const openLoginModal = React.useCallback(async () => {
    setLoginModalShow(true)
  }, [])

  const confirmLoginModal = React.useCallback(async () => {
    setLoginModalShow(false)
    history.push('/')
  }, [history])

  const closeLoginModal = React.useCallback(async () => {
    setLoginModalShow('')
  }, [])

  const closeDepositAktifModal = React.useCallback(async () => {
    setDepositAktifModal(false)
  }, [setDepositAktifModal])

  const openDepositAktifModal = React.useCallback(async () => {
    setDepositAktifModal(true)
  }, [setDepositAktifModal])

  const confirmRedirrectModal = React.useCallback(async () => {
    if (appUserId) {
      await dispatch(logout())
    } else {
      window.location.href = process.env.REACT_APP_BASE_URL
    }
  }, [appUserId, dispatch])

  const closeRedirrectModal = React.useCallback(async () => {
    if (appUserId) {
      await dispatch(logout())
    } else {
      window.location.href = process.env.REACT_APP_BASE_URL
    }
  }, [appUserId, dispatch])

  const onRefreshSaldo = React.useCallback(async () => {
    setRefreshSaldo(true)

    const resetResponse = await dispatch(refreshSaldo(userId))
    if (resetResponse.isOK()) {
      setRefreshSaldo(false)
    } else {
      setAlertError({ rc: resetResponse.getRC(), rd: resetResponse.getRD() })
    }
  }, [dispatch, userId, setRefreshSaldo, setAlertError])

  const redirectTo = React.useCallback(
    (e) => {
      const { path } = e.currentTarget.dataset
      history.push(path)
    },
    [history]
  )

  const redirectToReport = React.useCallback(
    (e) => {
      const { path } = e.currentTarget.dataset
      history.push({
        pathname: '/report/transaction',
        state: {
          tipe: path,
          title: path === 'all' ? '' : path.charAt(0).toUpperCase() + path.slice(1),
          startTgl:
            path === 'all'
              ? DateTime.startOfMonth(DateTime.FMT_DATE_YMD)
              : DateTime.today(DateTime.FMT_DATE_YMD),
          endTgl:
            path === 'all'
              ? DateTime.endOfMonth(DateTime.FMT_DATE_YMD)
              : DateTime.today(DateTime.FMT_DATE_YMD),
        },
      })
    },
    [history]
  )

  const redirectToPromo = React.useCallback((e) => {
    const { url } = e.currentTarget.dataset
    if (url.includes('https://') || url.includes('http://')) {
      Browser.openInNewTab(url)
    } else {
      Browser.openInNewTab(process.env.REACT_APP_URL_LANDING + url)
    }
  }, [])

  const settingsSlider = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: sliderPromo.length >= 3 ? 3 : sliderPromo.length,
    slidesToScroll: sliderPromo.length >= 3 ? 3 : sliderPromo.length,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: sliderPromo.length >= 2 ? 2 : sliderPromo.length,
          slidesToScroll: sliderPromo.length >= 2 ? 2 : sliderPromo.length,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  }

  React.useEffect(() => {
    DOMHelper.mountClass('home-layout')
    HookHelper.resetLoading()

    // -- SLIDER PROMO
    dispatch(getSliderPromo(userId, appUserCampaign)).then((response) => {
      if (response.isOK()) {
        const resData = response.getData()
        const sliderData = resData.data
        setSliderPromo(sliderData)
      }
    })

    if (!appUserId && !appSettingMenu) dispatch(globalProduk(userId, userPin))
    if (!appProdukTagihan) dispatch(listProdukTagihan(userId))

    if (appUserId) {
      // -- REFRESH SALDO
      dispatch(refreshSaldo(userId))

      // -- LIST DEPOSIT
      dispatch(depositList(appUserId, '0')).then((response) => {
        if (response.isOK()) {
          const result = response.getResultAsList()
          const tiketAktif = _.filter(result, (item) => {
            return !DateTime.isExpired(item.time_request)
          })
          setDepositAktif(tiketAktif)
        }
      })

      // -- RESUME TRANSAKSI DAILY
      dispatch(resumeTrxDaily(appUserId)).then((response) => {
        if (response.isOK()) {
          const resData = response.getData()
          setResumeDaily(resData.data)
        }
      })

      // -- RESUME TRANSAKSI MONTHLY
      dispatch(resumeTrxMonth(appUserId)).then((response) => {
        if (response.isOK()) {
          const resData = response.getData()
          setResumeMonthly(resData.data)
        }
      })
    }

    // -- CHECK URL HREF
    // const currentURL = window.location.href

    // if (currentURL.includes('localhost')) {
    //   console.log('uee')
    //   setRedirrectModalShow(true)
    // }

    return () => DOMHelper.unmountClass(['home-layout'])
  }, [
    appUserId,
    userId,
    userPin,
    appSettingMenu,
    appProdukTagihan,
    appUserCampaign,
    setDepositAktif,
    setSliderPromo,
    setResumeDaily,
    setResumeMonthly,
    setRedirrectModalShow,
    dispatch,
  ])

  return (
    <Container fluid className="home-layout">
      {!appUserIsEmbed && (
        <Row className="header">
          <Col>
            <HeaderComponent title="Home" onToggle={toggleSidebar} />
          </Col>
        </Row>
      )}

      <Row>
        {!appUserIsEmbed && (
          <Col
            md={2}
            className={classNames('col-sidebar', { 'is-open': isOpen, 'is-mobile': isMobile })}
          >
            <SidebarComponent isOpen={isOpen} />
          </Col>
        )}
        <Col
          md={{
            span: appUserIsEmbed ? 12 : isOpen ? 10 : 12,
            offset: appUserIsEmbed ? 0 : isOpen ? 2 : 0,
          }}
          className="main-gradient"
        >
          <Jumbotron fluid className="banner">
            <Row>
              <Col>
                <Row>
                  <span style={{ fontSize: 28, color: 'black', fontWeight: 'bold' }}>
                    Selamat Datang di SpeedCash
                  </span>
                </Row>
                <Row>
                  <span style={{ fontSize: 16, color: 'black' }}>
                    Nikmati layanan pembayaran terlengkap dan termurah di Indonesia
                  </span>
                </Row>
              </Col>

              {!appUserId && (
                <Col lg={3} xs={12} md={3} style={{ margin: 'auto' }}>
                  <Button className="button-login" type="button" onClick={openLoginModal}>
                    Daftar / Login
                  </Button>
                </Col>
              )}
            </Row>
            {sliderPromo.length > 0 && !appUserIsEmbed && (
              <Row
                className={classNames('banner-slider', {
                  'slide-mobile': isMobile,
                  'slick-slider': !isMobile && sliderPromo.length === 1,
                })}
              >
                <Slider {...settingsSlider}>
                  {sliderPromo.map((item) => (
                    <div key={item.id}>
                      <Image
                        alt={item.title}
                        rounded
                        key={item.id}
                        src={item.url_image}
                        data-id={item.id}
                        data-title={item.title}
                        data-url={item.url_link}
                        height="100%"
                        width={isMobile || sliderPromo.length === 1 ? '100%' : '95%'}
                        className="pointing img-fit-contain"
                        onClick={redirectToPromo}
                      />
                    </div>
                  ))}
                </Slider>
              </Row>
            )}
          </Jumbotron>
          <div className="content-layanan">
            {appUserId && (
              <Row style={{ marginBottom: '20px' }}>
                <Col className="pd-10" md={6} lg={3} xs={12}>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Card.Title className="txt-16 txt-bold">Saldo Anda</Card.Title>
                        <Col className="text-end">
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={<Tooltip id="tooltip-top">Refresh Saldo</Tooltip>}
                          >
                            <FontAwesomeIcon
                              icon="sync"
                              fixedWidth
                              className="mr-3 txt-16 txt-bold color-primary pointing"
                              onClick={onRefreshSaldo}
                            />
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={9} lg={9} xs={9} style={{ margin: 'auto' }}>
                          {!isRefreshSaldo ? (
                            <Card.Text className="color-primary txt-24">
                              {appUserBalance
                                ? `Rp ${Formatter.price(Math.round(parseFloat(appUserBalance)))}`
                                : '-'}
                            </Card.Text>
                          ) : (
                            <Spinner animation="border" variant="primary" />
                          )}
                        </Col>
                        <Col md={3} lg={3} xs={3} className="text-end" style={{ margin: 'auto' }}>
                          <FontAwesomeIcon
                            icon="wallet"
                            fixedWidth
                            className="color-primary txt-48"
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer
                      className="text-muted pointing"
                      data-path="/deposit"
                      onClick={redirectTo}
                    >
                      <Row>
                        <Col className="txt-14">Isi Saldo</Col>
                        <Col className="text-end">
                          <FontAwesomeIcon icon="chevron-right" fixedWidth className="mr-3" />
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Card>
                </Col>
                <Col className="pd-10" md={6} lg={3} xs={12}>
                  <Card>
                    <Card.Body>
                      <Card.Title className="txt-16 txt-bold">Transaksi Bulan Ini</Card.Title>
                      <Row>
                        <Col md={9} lg={9} xs={9} style={{ margin: 'auto' }}>
                          <Card.Text className="color-primary txt-24">
                            {resumeMonthly ? resumeMonthly.total_all : '-'}
                          </Card.Text>
                        </Col>
                        <Col md={3} lg={3} xs={3} className="text-end" style={{ margin: 'auto' }}>
                          <FontAwesomeIcon
                            icon="file-alt"
                            fixedWidth
                            className="color-primary txt-48"
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer
                      className="text-muted pointing"
                      data-path="all"
                      onClick={redirectToReport}
                    >
                      <Row>
                        <Col className="txt-14">Lihat Laporan</Col>
                        <Col className="text-end">
                          <FontAwesomeIcon icon="chevron-right" fixedWidth className="mr-3" />
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Card>
                </Col>
                <Col className="pd-10" md={6} lg={3} xs={12}>
                  <Card>
                    <Card.Body>
                      <Card.Title className="txt-16 txt-bold">Transaksi Berhasil</Card.Title>
                      <Row>
                        <Col md={9} lg={9} xs={9} style={{ margin: 'auto' }}>
                          <Card.Text className="color-success txt-24">
                            {resumeDaily
                              ? `${resumeDaily.total_sukses} (Hari ini)`
                              : '- (Hari ini)'}
                          </Card.Text>
                        </Col>
                        <Col md={3} lg={3} xs={3} className="text-end" style={{ margin: 'auto' }}>
                          <FontAwesomeIcon
                            icon="check-circle"
                            fixedWidth
                            className="color-success txt-48"
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer
                      className="text-muted pointing"
                      data-path="sukses"
                      onClick={redirectToReport}
                    >
                      <Row>
                        <Col className="txt-14">Lihat Laporan</Col>
                        <Col className="text-end">
                          <FontAwesomeIcon icon="chevron-right" fixedWidth className="mr-3" />
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Card>
                </Col>
                <Col className="pd-10" md={6} lg={3} xs={12}>
                  <Card>
                    <Card.Body>
                      <Card.Title className="txt-16 txt-bold">Transaksi Gagal</Card.Title>
                      <Row>
                        <Col md={9} lg={9} xs={9} style={{ margin: 'auto' }}>
                          <Card.Text className="color-danger txt-24">
                            {resumeDaily ? `${resumeDaily.total_gagal} (Hari ini)` : '- (Hari ini)'}
                          </Card.Text>
                        </Col>
                        <Col md={3} lg={3} xs={3} className="text-end" style={{ margin: 'auto' }}>
                          <FontAwesomeIcon
                            icon="times-circle"
                            fixedWidth
                            className="color-danger txt-48"
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer
                      className="text-muted pointing"
                      data-path="gagal"
                      onClick={redirectToReport}
                    >
                      <Row>
                        <Col className="txt-14">Lihat Laporan</Col>
                        <Col className="text-end">
                          <FontAwesomeIcon icon="chevron-right" fixedWidth className="mr-3" />
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            )}

            {depositAktif.length > 0 && (
              <Row className="rw-body">
                <Col
                  md={2}
                  lg={2}
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <FontAwesomeIcon
                    icon="wallet"
                    fixedWidth
                    className="mr-4 color-primary icon-48"
                  />
                </Col>
                <Col md={7} lg={7} xs={12}>
                  <Row style={{ padding: '5px' }}>
                    <span className="label-bold color-primary txt-20">{`${depositAktif.length} Tiket Deposit Anda masih Aktif`}</span>
                  </Row>
                  <Row style={{ padding: '5px' }}>
                    <span className="label-text txt-14">
                      Selesaikan proses transfer sebelum
                      <span className="label-text txt-14 txt-bold">
                        {` ${DateTime.getExpired(depositAktif[0].time_request)}`}
                      </span>
                    </span>
                  </Row>
                </Col>
                <Col md={3} lg={3} xs={12} onClick={openDepositAktifModal}>
                  <div className="label-text color-primary pointing">
                    Klik untuk lihat detail tiket
                  </div>
                </Col>
              </Row>
            )}
            <h4>Layanan Pembayaran</h4>
            <Row>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/isi-pulsa-termurah"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="Pulsa & Data"
                    src="/icon/layanan/pulsa.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">Pulsa & Data</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/transfer/bank"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="Transfer Antar Bank"
                    src="/icon/layanan/transfer.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">Transfer Antar Bank</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/transfer/spesial"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="Transfer Bank Spesial"
                    src="/icon/layanan/trfspesial.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">Transfer Bank Spesial</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/ecommerce"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="Pembayaran E-Commerce"
                    src="/icon/layanan/ecommerce.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">Pembayaran E-Commerce</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/emoney"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="Top Up E-money"
                    src="/icon/layanan/emoney.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">Top Up E-money</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/game"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="Voucher Game"
                    src="/icon/layanan/game.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">Voucher Game</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/pln"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="PLN"
                    src="/icon/layanan/pln.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">PLN</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/penerimaan-negara"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="Penerimaan Negara MPN"
                    src="/icon/layanan/djkm.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">Penerimaan Negara MPN</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/pdam"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="PDAM"
                    src="/icon/layanan/pdam.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">PDAM</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/bpjs"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="BPJS Kesehatan"
                    src="/icon/layanan/bpjs.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">BPJS Kesehatan</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/telkom"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="Telkom & Indihome"
                    src="/icon/layanan/telkom.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">Telkom & Indihome</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/tv"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="TV Berlangganan"
                    src="/icon/layanan/tv.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">TV Berlangganan</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/asuransi"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="Asuransi"
                    src="/icon/layanan/asuransi.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">Asuransi</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/kartukredit"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="Kartu Kredit"
                    src="/icon/layanan/kartukredit.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">Kartu Kredit</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/finance"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="Angsuran Kredit"
                    src="/icon/layanan/cicilan.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">Angsuran Kredit</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/pascabayar"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="Telepon Pascabayar"
                    src="/icon/layanan/pascabayar.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">Telepon Pascabayar</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/zakat"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="Zakat"
                    src="/icon/layanan/zakat.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">Zakat</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/gas"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="PGN"
                    src="/icon/layanan/gas.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">PGN</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/e-samsat"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="Pajak Kendaraan"
                    src="/icon/layanan/pajakmotor.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">Pajak Kendaraan</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/pbb"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="Pajak Daerah"
                    src="/icon/layanan/pbb.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">Pajak Daerah</span>
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="pointing icon-col"
                data-path="/transfer/saldo"
                onClick={redirectTo}
              >
                <div className="icon-list">
                  <Image
                    alt="Transfer Antar Member"
                    src="/icon/layanan/trfmember.png"
                    width="80"
                    height="80"
                    roundedCircle
                  />
                  <span className="icon-label">Transfer Antar Member</span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Alert
        header="Perhatian"
        btnText="OK"
        text={alertError.rd}
        type="error"
        show={!!alertError.rd}
        onClosePress={closeError}
        showBorderBottom
        alertStyles={{ justifyContent: 'center' }}
      />
      <LoginModal show={!!loginModalShow} onHide={closeLoginModal} onConfirm={confirmLoginModal} />
      <RedirrectModal
        show={!!redirrectModalShow}
        onHide={closeRedirrectModal}
        onConfirm={confirmRedirrectModal}
      />
      <DepositAktifModal
        show={!!depositAktifModal}
        onHide={closeDepositAktifModal}
        status="0"
        dataDepositAktif={depositAktif}
      />
    </Container>
  )
}

HomeScreen.defaultProps = {
  // appIsLoading: false,
  appUserId: '',
  appUserPin: '',
  appSettingMenu: '',
  appUserBalance: 0,
  appUserCampaign: {
    utm_term: '',
    utm_user_id: '',
    utm_campaign: '',
    utm_name: '',
    utm_medium: '',
    utm_content: '',
    utm_source: '',
  },
  appUserIsEmbed: false,
  appProdukTagihan: null,
}

HomeScreen.propTypes = {
  appUserId: PropTypes.string,
  appUserPin: PropTypes.string,
  appSettingMenu: PropTypes.string,
  appUserBalance: PropTypes.number,
  appUserCampaign: PropTypes.object,
  appUserIsEmbed: PropTypes.bool,
  appProdukTagihan: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appSettingMenu: state.Global[globalConstant.SETTING_MENU],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserPin: state.Auth[authConstant.USER_PIN],
    appUserBalance: state.Auth[authConstant.USER_BALANCE],
    appUserCampaign: state.Auth[authConstant.USER_CAMPAIGN],
    appUserIsEmbed: state.Auth[authConstant.USER_IS_EMBED],
    appProdukTagihan: state.Produk[produkConstant.PRODUK_TAGIHAN],
  }),
  (dispatch) => ({ dispatch })
)(HomeScreen)
